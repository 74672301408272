import { useState } from "react";
import { Button, Confirm, useListContext, useUpdateMany, useNotify, useRefresh, useUnselectAll, useUnselect } from "react-admin";
import { styled } from '@mui/system';


const RedownloadButton = styled(Button)(({ theme }) => ({
    background: '#8AC2EC',
        color: 'black',
        marginLeft: '5px'
  }));

const DocumentRedownloadButton = (props) => {
    const {resource, selectedIds} = useListContext()
    const unselect = useUnselect(resource)
    const [open, setOpen] = useState(false)
    const refresh = useRefresh();
    const notify = useNotify();
    const API_URL = (window.frameElement && window.frameElement.getAttribute("data-api-url")) || (process && process.env && process.env.REACT_APP_API_URL);

    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    if(regexExp.test(props.filterValues.uuid)){
        const cust_uuid = props.filterValues.uuid
        var requestOptions = {

            method: 'PATCH',
            headers: { 'Authorization': `Bearer ${localStorage.getItem("token")}`, 'Content-Type': 'application/json'},
            body: JSON.stringify({"ids": selectedIds, "customer_uuid" : cust_uuid})
        }
    }
    else{
        const client_key = props.filterValues.uuid
        var requestOptions = {

            method: 'PATCH',
            headers: { 'Authorization': `Bearer ${localStorage.getItem("token")}`, 'Content-Type': 'application/json'},
            body: JSON.stringify({"ids": selectedIds, "client_key" : client_key})
        }
    }

    const handleUpdateDocument = () => {
    var requestUrl = `${API_URL}/internal/document/update/`
    fetch(requestUrl, requestOptions)
    .then((response) => {
        if(response.ok){
            setOpen(false)
            refresh();
            response.json().then((resp) => {
                notify(`Document marked for redownload`, {
                    type: "info",
                });
            });
        } else {
            setOpen(false);
            refresh();
            notify(`Document not marked for removal. Status: ${response.status}`, {
                type: "warning",
            });
        }
    })
    .catch((error) => {
        notify(`Request failed. ${error}`, {type: "warning"});
    });

  }; 

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const handleConfirm = () => {
        handleUpdateDocument();
        unselect(selectedIds)
        setOpen(false);
    };


    return (
        <>
            <RedownloadButton id="RedownloadButton" label="Mark for Redownload" onClick={handleClick} />
            <Confirm
                isOpen={open}
                title="Mark selected documents for redownload"
                content="Are you sure you want to submit these documents for redownload"
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </>
    );
};


export default DocumentRedownloadButton;


