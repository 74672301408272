import { InputAdornment, TextField } from "@mui/material";
import { useInput } from "react-admin"

const CurrencyInput = (props) => {
  const { source, label, ...rest } = props;

  const BoundedTextField = (props) => {
    const { onChange, onBlur, label, ...rest } = props;
    const {
      field,
      fieldState: { isTouched, invalid, error },
      formState: { isSubmitted },
      isRequired
    } = useInput({
      // Pass the event handlers to the hook but not the component as the field property already has them.
      // useInput will call the provided onChange and onBlur in addition to the default needed by react-hook-form.
      onChange,
      onBlur,
      ...rest,
    });

    return (
      <TextField
        type="number"
        {...field}
        label={label}
        placeholder={"0.00"}
        error={(isTouched || isSubmitted) && invalid}
        helperText={(isTouched || isSubmitted) && invalid ? error?.message : ""}
        required={isRequired}
        margin="normal"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">$</InputAdornment>
          )
        }}
        onWheel={(e) => e.target.blur()}
        {...rest}
      />
    );
  };
  
  return (
    <BoundedTextField id={source} source={source} label={label || source} {...rest} />
  );
};

export default CurrencyInput;