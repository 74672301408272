/* 
 * RA validators return error message on error or
 * undefined on success.
 */
export const validateJSON = (json) => {
    if (json === undefined) return undefined;
    if (json === "") return undefined;
    if (json === null) return undefined;
    try {
        JSON.parse(json);
    } catch (e) {
        return 'invalid json';
    }
    return undefined;
}