import { useState } from "react";
import { Button, Confirm, useNotify, useRefresh, useRecordContext } from "react-admin";
import { styled } from '@mui/system';

const CreateButton = styled(Button)(({ theme }) => ({
    background: '#8AC2EC',
        color: 'black',  
        marginLeft: 'auto',
        display: 'block',
        width: '100px', 
  }));

const KeySwitchCreateButton = () => {
    const [open, setOpen] = useState(false)
    const refresh = useRefresh();
    const notify = useNotify();

    const record = useRecordContext();

    const app_client_key = record.client_key

    const API_URL = ( window.frameElement && window.frameElement.getAttribute("data-api-url")) || (process && process.env && process.env.REACT_APP_API_URL);

    const handleCreateKeySwitch = (destinationKey) => {
        var requestUrl = `${API_URL}/internal/apps/switcher/${app_client_key}/${destinationKey}`
        var requestOptions = {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${localStorage.getItem("token")}`, 'Content-Type': 'application/json'},
        }

        fetch(requestUrl, requestOptions)
            .then((response) => {
                if(response.ok){
                    setOpen(false);
                    refresh();
                    response.json().then((resp) => {
                        notify(`Token has been added for ${destinationKey}`, {
                            type: "info",
                        });
                    });
                }else {
                    setOpen(false);
                    refresh();
                    notify(`${destinationKey} not in client group '${app_client_key}'. Status: ${response.status}`, {
                        type: "warning",
                    });
                }
            })
            .catch((error) => {
                notify(`Request failed. ${error}`, {type: "warning"});
            });
    };

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const handleConfirm = () => {
        const destinationKey = prompt('Client Key?')
        handleCreateKeySwitch(destinationKey);
        setOpen(false);
    };

    return (
        <>
            <CreateButton id="CreateKeySwitch" label="Create" onClick={handleClick} />
            <Confirm
                isOpen={open}
                title="Create Key Switcher"
                content="Do you want to create a key switcher?"
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </>
    );
};



export default KeySwitchCreateButton;