import React from "react";
import { styled } from '@mui/system';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import redWarningInMessageDocumentQualityError from '../resources/img/red-warning.jpg'
import grayWarningInMessageDocumentQualityError from '../resources/img/gray-warning.jpg'
import { List, ListItem } from "@mui/material";

const Heading = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(15),
  fontWeight: theme.typography.fontWeightRegular,
}));

const CustomList = styled(List)(({ theme }) => ({
   listStyleType: 'disc', 
   paddingLeft: '2rem'
}))
const CustomListItem = styled(ListItem)(({ theme }) => ({
  display: 'list-item'
}))


export default function ClientEditDoc() {
  return(
    <div style={{width:'60%', marginLeft:'3em'}}>
      <h1>More Info</h1>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
          <Heading>Client Name</Heading>
        </AccordionSummary>
        <AccordionDetails>
            <Typography component="div">The client name is the customer’s company name</Typography>
            <CustomList>
              <CustomListItem>This can be found from the environment request form</CustomListItem>
              <CustomListItem>This can be altered later</CustomListItem>
              <CustomListItem>If this is for a sandbox or guest environment, add “ (dev)” or " (guest)" respectively to the ending.</CustomListItem>
            </CustomList>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
          <Heading>Client Key</Heading>
        </AccordionSummary>
        <AccordionDetails>
            <Typography component="div">The client key should resemble the customer</Typography>
            <CustomList>
              <CustomListItem>This <b>CANNOT</b> be altered later</CustomListItem>
              <CustomListItem>No spaces, only hyphens</CustomListItem>
              <CustomListItem>No numbers, all lowercase</CustomListItem>
              <CustomListItem>Can't start with a number</CustomListItem>
              <CustomListItem>If this is for a sandbox or guest environment, add “-dev” or "-guest" respectively to the ending</CustomListItem>
            </CustomList>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
          <Heading>Client Type</Heading>
        </AccordionSummary>
        <AccordionDetails>
            <Typography component="div">The type of environment being created</Typography>
            <CustomList>
              <CustomListItem>For types Guest and Sandbox, you must append the proper suffixes to the name and client key</CustomListItem>
              <CustomListItem>No suffixes are needed for a Live environment</CustomListItem>
            </CustomList>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
          <Heading>Live Client Key</Heading>
        </AccordionSummary>
        <AccordionDetails>
            <Typography component="div">This is the primary environment the client is tied to.</Typography>
            <CustomList>
              <CustomListItem>If the client does <b>not</b> have an associated app, this will need to be set for a user's load updates and push notifications to function properly.</CustomListItem>
            </CustomList>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
          <Heading>Client Group Key</Heading>
        </AccordionSummary>
        <AccordionDetails>
          <Typography component="div">This key is defined when the client group is created.</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
          <Heading>Hubspot Company ID</Heading>
        </AccordionSummary>
        <AccordionDetails>
            <Typography component="div">The ID of the corresponding Hubspot company.</Typography>
            <CustomList>
              <CustomListItem>To find the ID, look up the name of the customer in this <a href="https://app.periscopedata.com/app/eleostech/595359/Platform-Support?widget=18319420&udv=0" target="_blank">report</a> </CustomListItem>
              <CustomListItem>If you cannot find the appropriate company in the report, reach out to the company's account executive</CustomListItem>
            </CustomList>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
          <Heading>Hubspot Deal ID</Heading>
        </AccordionSummary>
        <AccordionDetails>
            <Typography component="div">The ID of the corresponding Hubspot deal.</Typography>
            <CustomList>
              <CustomListItem>This field should almost always be filled, only in rare circumstances should this be left empty</CustomListItem>
              <CustomListItem>To find the ID, look up the name of the deal/customer in this <a href="https://app.periscopedata.com/app/eleostech/595359/Platform-Support?widget=18319610&udv=0" target="_blank">report</a></CustomListItem>
              <CustomListItem>If you cannot find the appropriate deal in the report, reach out to the company's account executive</CustomListItem>
            </CustomList>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
        <Heading>Billing Account</Heading>
        </AccordionSummary>
        <AccordionDetails>
            <Typography component="div">The UUID is the billing account number that is associated with the relevant reseller/house account</Typography>
            <CustomList>
              <CustomListItem>If the appropriate billing account does not appear in the list, let Ryan Crum know</CustomListItem>
              <CustomListItem>The UUID list can be found in Periscope</CustomListItem>
            </CustomList>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
          <Heading>Guest Client</Heading>
        </AccordionSummary>
        <AccordionDetails>
            <Typography component="div">This is the environment that a user enters when selecting guest mode at login.</Typography>
            <CustomList>
              <CustomListItem>If naming conventions were followed properly, the guest client should have the same name as this client but with "(guest)" at the end.</CustomListItem>
              <CustomListItem>The guest client must be in the same client group as this client.</CustomListItem>
              <CustomListItem>The associated guest client must be of type "guest"</CustomListItem>
              <CustomListItem>A client cannot be its own guest client.</CustomListItem>
            </CustomList>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
          <Heading>Backend User Invite Emails</Heading>
        </AccordionSummary>
        <AccordionDetails>
            <Typography component="div">Emails to send invites to be Backend Users for this client.</Typography>
            <CustomList>
              <CustomListItem>Should only be set when creating a <b>fresh customer, not for another reseller environment</b></CustomListItem>
              <CustomListItem>These invites gives these backend users full admin permissions, including ROAMING permissions (can switch between environments in the Hub)</CustomListItem>
            </CustomList>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
          <Heading>Document Quality notes</Heading>
        </AccordionSummary>
        <AccordionDetails>
            <Typography component="div">Determines the image quality threshold to display a warning in the app if the assigned image quality is below the threshold</Typography>
            <Typography component="div">When creating a new client, the default values are:</Typography>
            <CustomList>
              <CustomListItem>min_good_quality = .4</CustomListItem>
              <CustomListItem>max_bad_quality = .5</CustomListItem>
            </CustomList>
            <Typography component="div">Since the default values have the max_bad_quality above the min_good_quality only the red warning is displayed when the document quality score is below .5</Typography>
        </AccordionDetails>
        <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
          <Heading>Gray Warning Threshold (min_good_quality)</Heading>
        </AccordionSummary>
        <AccordionDetails>
            <Typography component="div">min_good_quality is the threshold that determines if a gray warning appears on the device if the image quality is less than the threshold set. The gray warning will read "This image maybe low quality"</Typography>
            <img src={grayWarningInMessageDocumentQualityError} width="400" height="auto"/>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
          <Heading>Red Warning Threshold (max_bad_quality)</Heading>
        </AccordionSummary>
        <AccordionDetails>
            <Typography component="div">max_bad_quality is the threshold that determines if a red warning appears on the device if the scanned image is less than that threshold set. The red warning will read "This image is very low quality."</Typography>
            <img src={redWarningInMessageDocumentQualityError} width="400" height="auto"/>
        </AccordionDetails>
      </Accordion>
      </Accordion>
    </div>
  )
}
