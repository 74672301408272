import React, { useState } from "react";
import { renderIfPermissions } from "../api/permissionManager";
import PublishIcon from '@mui/icons-material/Publish';

export const ParseSnapshotJson = (jsonData) => {
  var segments;

  // check if stitched geometry
  if(jsonData["stitchedGeometry"] != null) {
    segments = jsonData["stitchedGeometry"][0]["geometry"]

    for(var i = 1; i < jsonData["stitchedGeometry"].length; i++){
     segments.concat(jsonData["stitchedGeometry"][i]["geometry"])
    }

   segments = segments.map(item => {
      var lat = item.latitude
      var lng = item.longitude

      if (lat == null)lat = item.lat
      if(lng == null)lng = item.long

      return {
        "lat": lat,
        "lng": lng 
      };
    });

    return segments
  }

  // concat all the geometry segments together
  var response = jsonData["response"]

  if(Array.isArray(response)) {
    if(response[0]["pluggable_engine_response"] != null){
      segments = response[0]["pluggable_engine_response"]["geometry"]
    }
    else {
      segments = response[0]["geometry"]
    }

    for(var i = 1; i < response.length; i++){
      if(response[0]["pluggable_engine_response"] != null){
        segments.concat(response[i]["pluggable_engine_response"]["geometry"])
      }
      else {
       segments.concat(response[i]["geometry"])
      }
    }
  }
  else {
    segments = response["geometry"]
  }

  segments = segments.map(item => {
      var lat = item.latitude
      var lng = item.longitude

      if (lat == null)lat = item.lat
      if(lng == null)lng = item.long

      return {
        "lat": lat,
        "lng": lng 
      };
    });

  return segments
}

export const RouteUploadComponent = (props) => {
  const [fileReader] = useState(new FileReader());
  const updateRouteData = props.updateRouteData
  const updateLoading = props.updateLoading

  const handleFileUpload = (event,props) => {
    fileReader.onloadend = handleFileRead;
    fileReader.readAsText(event.target.files[0]);
  }

  const handleFileRead = (event,props) => {
    updateLoading(true)
    var data = fileReader.result;

    var jsonData;

    try {
      jsonData = JSON.parse(fileReader.result);
    }catch(e) {
      updateLoading(false)
      alert("Invalid JSON");
      return;
    }

    try {
      var segments = ParseSnapshotJson(jsonData)
      updateRouteData(segments)
    }catch(e){
      console.error(e)
      updateLoading(false)
      alert("Invalid snapshot JSON")
      return;
    }
  }

  const buttonStyle = {
     display: "inline-block",
     margin: "0 0.3em 0.3em 0",
     borderRadius: "2em",
     boxSizing: "border-box",
     textDecoration: "none",
     fontFamily: 'Roboto',
     fontWeight: "300",
     color: "#FFFFFF",
     backgroundColor: "#4eb5f1",
     textAlign: "center",
     cursor: "pointer"
  }

  const uploadBtn = <button style={buttonStyle}><label htmlFor="inputfile" style={{cursor: "pointer"}}><PublishIcon /></label></button>

  return (
    <div id="FileHandler">
      <input type="file" className="inputfile" id="inputfile" onChange={(e)=> {handleFileUpload(e,props); e.target.value=null }} hidden/>
      <h4>Upload Route Snapshot JSON {uploadBtn}</h4>
    </div>
  );
}
