import React from "react";
import {
  useRecordContext,
  Create,
  SimpleForm,
  TextInput,
  required,
  NotFound,
  useCreatePath,
  useGetList,
  Loading,
} from "react-admin";
import { Button as MaterialButton, Typography } from "@mui/material";
import { useSearchParams, Link as RouterLink } from "react-router-dom";
import LockResetIcon from '@mui/icons-material/LockReset';
import DraftsTable from "../components/DraftsTable";


export const RotateKeyButton = (props) => {
  const record = useRecordContext();
  const createPath = useCreatePath();
  return (
    <MaterialButton
      id="rotate-key-button"
      color="primary"
      component={RouterLink}
      style={{ fontSize: "0.8125rem", padding: ".15rem" }}
      to={
        createPath({
          resource: "customer_key_rotation_drafts",
          type: "create",
        }) + `?uuid=${encodeURIComponent(record.id)}&name=${props.name}`
      }
    >
      <LockResetIcon
        style={{ fontSize: "1.25rem", paddingRight: "0.5rem" }}
      />
      Rotate Key
    </MaterialButton>
  );
};

export const CustomerAxleKeyRotationDraftsField = () => {
  const record = useRecordContext();

  const { data, isLoading, error } = useGetList(
    "customer_key_rotation_drafts",
    {
      filter: { "event.path": `/${record.id}` },
      pagination: { page: 1, perPage: 10 }
    }
  );

  if (isLoading) { return <Loading /> }
  if (error) { return <p>ERROR</p> }
  return (
    <DraftsTable data={data} resource={"customer_key_rotation_drafts"}/>
  );
};

const validateDraftCreation = (name, values) => {
  const errors = {};
  if (!values.confirmation) {
    errors.confirmation = "You must enter the customer's name to confirm";
  } else if (name !== values.confirmation) {
    errors.confirmation = "The name entered must match the customer's name exactly.";
  }
  return errors;
}

export const CustomerAxleKeyRotationDraftsCreate = () => {
  const [searchParams] = useSearchParams();
  const uuid = searchParams.get("uuid");
  const name = searchParams.get("name");
  const createPath = useCreatePath();
  if (uuid == null) return <NotFound />;
  return (
    <Create
      title={`Rotate Drive Axle Key for Customer: ${name}`}
      redirect={createPath({ resource: "customers", id: uuid, type: "show" }) + "?rotation_draft_created="}
    >
      <SimpleForm validate={(values) => validateDraftCreation(name, values)}>
        <Typography>You are about to rotate the Drive Axle key for Customer: <b>{name}</b>
          <span>
            <Typography>Please type the customer name to confirm.</Typography>
          </span>
        </Typography>
        <TextInput label="Customer Name" source="confirmation" resettable validate={required()} />
        <input hidden defaultValue={name} source="name" />
        <TextInput source="uuid" defaultValue={uuid} fullWidth disabled />
      </SimpleForm>
    </Create>
  );
};
