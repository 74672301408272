import React from "react";
import { List, Datagrid, BooleanField, TextField, TextInput, Filter, DateField, 
         Show, SimpleShowLayout, useListContext, useShowController, usePermissions
       } from "react-admin";
import { isEmpty } from "lodash";
import { renderIfPermissions } from "../api/permissionManager";
import { ResetPasswordButton } from "../components/PasswordReset";

const DriveAxleFilter = (props) => (
  <Filter {...props}>
      <TextInput label="User Email" source="email" alwaysOn/>
  </Filter>
);

export const DriveAxleShow = (props) => {
  const {record} = useShowController()
  const {permissions} = usePermissions()

  return(
    <Show {...props}>
        <SimpleShowLayout>
          <TextField source="name"/>
          <TextField source="email"/>
          <DateField source="last_sign_in_at" showTime/>
          <DateField source="created_at" showTime/>
          <BooleanField source="active"/>
          <TextField source="last_device_name"/>
          <TextField source="last_device_type"/>
          <TextField source="last_device_identifier"/>
          <TextField source="last_user_agent"/>
          <TextField source="last_drive_axle_version"/>
          {renderIfPermissions(permissions, "customers", "write",
            <ResetPasswordButton record={record} {...props}/> 
          )}
        </SimpleShowLayout>
    </Show>
  );
}

const CustomDriveAxleList = (props) => {
  const { filterValues } = useListContext(props);
  if(isEmpty(filterValues)){
    return (
        <h2 align='center' style={{margin: 35, padding: 10}}>Enter an email to view matching users.</h2>
    )
  }
  return (
    <Datagrid key={props.key} rowClick="show" bulkActionButtons={false} size="medium">
      <TextField source="name"/>
      <TextField source="email"/>
      <DateField source="last_sign_in_at" showTime/>
      <DateField source="created_at" showTime/>
    </Datagrid>
  );
}

export const DriveAxleList = (props) => (
    <List {...props} pagination={false} bulkActionButtons={false} filters={<DriveAxleFilter/>} exporter={false}>
      <CustomDriveAxleList {...props} />
    </List>
)
