import React, { useEffect, useState } from "react";
import {
  Create, DateField, SimpleForm, List, Datagrid, TextField, TextInput,
  ReferenceManyField, SelectInput, SingleFieldList, ChipField, required, Show,
  usePermissions, TopToolbar, Button, EditButton,
  useShowController, TabbedShowLayoutTabs, TabbedShowLayout, Tab, useRedirect,
  Toolbar, SaveButton, DateInput, useUpdate, useGetManyReference, FunctionField,
  useRefresh, useNotify,
  ReferenceInput,
  AutocompleteInput
} from "react-admin";
import { Box, Popover, Alert, AlertTitle } from "@mui/material";
import { styled } from "@mui/system";
import { BillingAccountCreateDoc } from "../documentation/CreateBillingAccountDoc"
import { renderIfPermissions } from "../api/permissionManager";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Add, CheckBox, CheckBoxOutlineBlank, KeyboardArrowUp } from "@mui/icons-material";
import EmptyComponent from "../components/EmptyComponent";
import CustomAccountContractCreateDoc from "../documentation/CreateCustomAccountContractDoc";

const CancelButton = styled(Button)(({ theme }) => ({
  '&.MuiButton-outlinedSecondary': {
    border: '1px solid gray',
    color: 'gray',
    paddingLeft: '1.5em',
    paddingRight: '1.5em',
    paddingTop: '0.6em',
    paddingBottom: '0.6em',
    margin: '2em',
  },
}));

const BillingAccountShowActions = ({ id, acct_type, name }) => {
  const { permissions } = usePermissions()
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    localStorage.removeItem("billingAcct");
    setAnchorEl(null);
  };

  const handleCreateButtonClick = () => {
      navigate('/reseller_billing_account/create', { state: { id, name } });
  };

  return (
    <TopToolbar>
      {renderIfPermissions(permissions, "billing", "write",
        <>
          <Button label="Add"
            startIcon={open ? <KeyboardArrowUp /> : <KeyboardArrowDownIcon />}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          />
          <Popover open={open} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
            <div style={{display:'block', width:'17em'}}>
              <Button startIcon={<Add />} label="Custom" onClick={handleCreateButtonClick} />
              {acct_type === 'house_account' && (<EditButton startIcon={<Add />} resource="per_load_billing_structure" label="Per-Load Billing Structure" />)}
            </div>
          </Popover>
        </>
      )}
    </TopToolbar>
  );
};


export const BillingAccountFilter = [
  <TextInput label="Search by name" source="name" alwaysOn />,
  <SelectInput label="Account type" source="billing_account_type"
    choices={[
      { id: 'reseller', name: 'Reseller' },
      { id: 'house_account', name: 'House Account' },
    ]}
  />,
  <TextInput label="Filter by price sheet code" source="price_sheet_code" />,
  <TextInput label="Filter by contract structure code" source="contract_structure_code" />
]

export const BillingAccountList = () => {
  return (
    <List pagination={false} filters={BillingAccountFilter} exporter={false}>
      <Datagrid rowClick="show" size="medium" bulkActionButtons={false}>
        <TextField source="name" sortable={false} />
        <TextField source="billing_account_type" label="Type" sortable={false} />
        <DateField source="contract_anniversary" sortable={false} options={{ timeZone: 'UTC' }} />
        <DateField source="valid_from" sortable={false} options={{ timeZone: 'UTC' }} />
        <TextField source="price_sheet_code" sortable={false} />
      </Datagrid>
    </List>
  );
}

const AccountContractUpdate = ({ id, contract, contractList, handleSetActiveContracts }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const [update, { isPending }] = useUpdate();

  const getLastDayOfPrevMonth = () => {
    const now = new Date();
    const lastDay = new Date(now.getFullYear(), now.getMonth(), 0);
    return new Date(lastDay).toLocaleDateString();
  };
  const findActiveContracts = (contracts) => {
    const activeContracts = contracts.filter(contract => contract.active);
    return activeContracts;
  }

  const handleClick = () => {
    let activeContracts = findActiveContracts(contractList)
    const alreadyActive = activeContracts.length > 0;
    const contractIsActive = contract.active;

    if (alreadyActive && !contractIsActive) {
      notify(
        'Please deselect the currently active contract before activating another.',
        { type: 'error' }
      );
      return;
    }

    const updatedContract = {
      ...contract,
      active: !contractIsActive,
      valid_to: contractIsActive ? new Date(getLastDayOfPrevMonth()).toISOString().slice(0, 10) : null,
      valid_from: new Date(contract.valid_from).toISOString().slice(0, 10),
    };

    update(
      'account_contracts',
      { id: id, data: updatedContract },
      {
        onSuccess: () => {
          refresh();
          notify(`Account contract ${id} successfully updated`, 'success');
          if (!contractIsActive) {
            handleSetActiveContracts([updatedContract]);
          } else {
            handleSetActiveContracts([]);
          }
        },
        onError: (error) => {
          notify(`Error updating account contract: ${JSON.stringify(error)}`, { type: 'error' });
        }
      }
    );
  };
  return (
    <Button disabled={isPending} onClick={handleClick} startIcon={contract.active ? <CheckBox /> : <CheckBoxOutlineBlank />} />
  );
};

export const BillingAccountShow = (props) => {
  const { id } = useParams();
  const {permissions} = usePermissions();
  const navigate = useNavigate();
  const { record } = useShowController();
  const [acctType, setAcctType] = useState();
  const [acctName, setAcctName] = useState();
  const [contracts, setContracts] = useState([]);
  const [alertSeverity, setAlertSeverity] = useState(null);
  const [alertMessage, setAlertMessage] = useState('');
  const [activeContracts, setActiveContracts] = useState([]);
  const { data: contractData, isLoading, error: listError } = useGetManyReference(
    'account_contracts',
    { target: 'billing_account_id', id: id }
  );

  useEffect(() => {
    if (record) {
      setAcctType(record.billing_account_type);
      setAcctName(record.name);
    }
    if (!isLoading && !listError) {
      setContracts(contractData);
      findActiveContracts();
    }
  }, [record, contractData, isLoading, listError]);

  const findActiveContracts = () => {
    const activeContracts = contracts.filter(contract => contract.active);
    setActiveContracts(activeContracts);
  }

  const handleSetActiveContracts = (contracts) => {
    setActiveContracts(contracts);
  }

  const handleCloseAlert = () => {
    setAlertSeverity(null);
    setAlertMessage('');
  };

  const handleCreateButtonClick = () => {
    const name = acctName
    navigate('/reseller_billing_account/create', { state: { id, name } });
  };
  return (
    <Show {...props}>
      <TabbedShowLayout
        tabs={<TabbedShowLayoutTabs variant="scrollable" scrollButtons="auto" {...props} />}
        spacing={2}
        style={{ paddingBottom: '16px' }}
        syncWithLocation={false}
      >
        <Tab label="Billing Account Information" path="info">
          <TextField source="name" />
          <TextField source="billing_account_type" />
          <TextField source="contract_structure_code" />
          <TextField source="price_sheet_code" />
          <DateField source="valid_from" options={{ timeZone: 'UTC' }} />
          <DateField source="contract_anniversary" options={{ timeZone: 'UTC' }} />
          <TextField source="external_id" />
          {renderIfPermissions(permissions, "clients", "read_only", 
            <ReferenceManyField label="Associated Clients" reference="clients" target="billing_account_id">
              <SingleFieldList linkType="show">
                <ChipField source="key" />
              </SingleFieldList>
            </ReferenceManyField>
          )}
        </Tab>
        <Tab label="Account Contracts" path="account_contracts">
          <BillingAccountShowActions id={id} name={acctName} acct_type={acctType} />
          <Box width="fit-content">
            {alertSeverity && (
              <Alert severity={alertSeverity} onClose={handleCloseAlert}>
                <AlertTitle>{alertSeverity === 'success' ? 'Success' : 'Error'}</AlertTitle>
                {alertMessage}
              </Alert>
            )}
          </Box>
          <ReferenceManyField label="Account Contracts" reference="account_contracts" target="billing_account_id" record={record}>
            <Datagrid bulkActionButtons={false} empty={<EmptyComponent permissionResource="billing" label={"Account Contracts"} onClick={handleCreateButtonClick} />}>
              {renderIfPermissions(permissions, "billing", "write", 
              <FunctionField
                label="Active?"
                render={(record) => (
                  <AccountContractUpdate
                    id={record.id}
                    contract={record}
                    contractList={contracts}
                    handleSetActiveContracts={handleSetActiveContracts}
                  />
                )}
              />)}
              <TextField source="contract_structure_code" />
              <TextField source="price_sheet_code" />
              <TextField source="created_by" />
              <DateField source="created_at" />
              <DateField source="valid_from" />
              <DateField source="valid_to" />
              <DateField source="contract_anniversary" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

const CustomAccountContractCreateToolbar = ({ id }) => {
  const redirect = useRedirect();
  const handleCancel = () => {
    redirect(`/billing_accounts/${id}/show`);
  };
  return (
    <Toolbar>
      <SaveButton label="Save" />
      <CancelButton
        variant="outlined"
        onClick={handleCancel}
        color="secondary"
        label="Cancel"
      />
    </Toolbar>
  )
}

export const CustomAccountContractCreate = () => {
  const location = useLocation();
  const { id, name } = location.state || {};
  return (
    <Create 
      resource="account_contracts" 
      redirect={`/billing_accounts/${id}/show`} 
      aside={<CustomAccountContractCreateDoc />}
      title={"Create Custom Account Contract"}
    >
      <SimpleForm toolbar={<CustomAccountContractCreateToolbar id={id} />}>
        <TextInput
          source="billing_account_external_id"
          validate={required()}
          defaultValue={id}
          disabled
          fullWidth
        />
        <TextInput
          source="name"
          validate={required()}
          defaultValue={name}
          disabled
          fullWidth
        />
        <ReferenceInput source="contract_structure_code" reference='contract_structures'>
            <AutocompleteInput fullWidth optionText="code" validate={required()} />
        </ReferenceInput>
        <ReferenceInput source="price_sheet_code" reference='price_sheets'>
            <AutocompleteInput fullWidth optionText="code" validate={required()} />
        </ReferenceInput>
        <DateInput source="valid_from" validate={required()} fullWidth />
        <DateInput source="contract_anniversary" validate={required()} fullWidth />
      </SimpleForm>
    </Create>
  );
};

export const BillingAccountCreate = () => {
  return (
    <Create resource="billing_accounts_v2"
      redirect={'/billing_accounts'} 
      title="Create Billing Account"
      aside={<BillingAccountCreateDoc />}
    >
      <SimpleForm>
        <TextInput 
          fullWidth
          source="name" 
          label="Billing Account Name" 
          validate={required()}
        />
        <SelectInput
          fullWidth
          source="type"
          validate={required()}
          choices={[
            { id: 'house_account', name: 'House Account' },
            { id: 'reseller', name: 'Reseller' },
          ]}
        />
        <TextInput
          fullWidth 
          source="hubspot_company_identifier"
          label="HubSpot Company Identifier" 
          validate={required()}
        />
      </SimpleForm>
    </Create>
  );
};