import React from 'react';
import { Typography } from "@mui/material";
import emptyImg from "../resources/img/empty-24.svg"
import { Button, usePermissions } from "react-admin";
import { Add } from "@mui/icons-material";
import { renderIfPermissions } from '../api/permissionManager';


/***************************************************************************************************\
 * React Admin's Empty component will auto redirect you to the Create Page for the resource.        *
 * That's nice. However, sometimes we want to override the create button for certain tasks.         *
 *         (E.g.: Grab the record's id and auto fill a property in the create page)                 *
 * Unfortunately, React Admin will not let you override the create button their Empty component.    *
 *                                  So, I made my own =)                                            *
\***************************************************************************************************/
const EmptyComponent = ({ permissionResource, label, onClick }) => {
    const { permissions } = usePermissions();
    return (
        <div style={{ textAlign: 'center' }}>
            <img
                style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '10%' }}
                src={emptyImg}
                alt={`No ${label}`}
            />
            <Typography style={{ color: '#858585', fontFamily: '"Roboto","Helvetica","Arial","sans-serif"' }} variant="h4">
                No {label} yet.
            </Typography>
            {renderIfPermissions(permissions, permissionResource, "write",
                <>
                    <Typography style={{ color: '#858585', marginTop: '1em', fontFamily: '"Roboto","Helvetica","Arial","sans-serif"' }}>
                        Do you want to add one?
                    </Typography>

                    <Button
                        style={{ marginTop: '2em', marginBottom: '2em' }}
                        variant="contained"
                        label="create"
                        startIcon={<Add />}
                        onClick={onClick}
                    >
                        Add {label}
                    </Button>
                </>
            )}
        </div>
    )
};

export default EmptyComponent;