import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_GET_PERMISSIONS, AUTH_CHECK } from 'react-admin';
import permissionManager from './permissionManager';

const fetchPermissions = async () => {
    const API_URL = (window.frameElement && window.frameElement.getAttribute("data-api-url")) || (process && process.env && process.env.REACT_APP_API_URL);
    const requestUrl = `${API_URL}/internal/administrators/me/permissions`;
    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': `Bearer ${localStorage.getItem("token")}`, 'Content-Type': 'application/json' }
    };

    try {
        const response = await fetch(requestUrl, requestOptions);

        // Check if the response is a 403 or 401
        if (response.status === 403 || response.status === 401) {
            return Promise.reject({ status: 403, message: "403 Forbidden" });
        }

        const user_permissions = await response.json();
        const email = user_permissions.email;

        if (!user_permissions) {
            console.error('Request Failed: Could not fetch user permissions');
            return Promise.reject();
        }

        let granular_permissions = new Map();
        let platform_permissions = null;
        let billing_permissions = null;
        let permissions_management = null;
        if (email) localStorage.setItem('user_email', email);

        // Handle new granular permissions format
        granular_permissions = new Map(Object.entries(user_permissions));
        if (granular_permissions.size > 10) {
            // Store granular permissions
            const granular_permissions_obj = Object.fromEntries(granular_permissions);
            localStorage.setItem('granular_permissions', JSON.stringify(granular_permissions_obj));
            return Promise.resolve(granular_permissions);
        }
        // Handle old permissions format (fallback)
        else {
            if (user_permissions.read_only && !user_permissions.super_admin) platform_permissions = 'read_only';
            if (user_permissions.super_admin) platform_permissions = 'full';
            if (user_permissions.billing_admin) billing_permissions = 'full';
            if (user_permissions.permissions_admin) permissions_management = 'full';
            permissionManager.setPerms(platform_permissions);
            permissionManager.setBillingPerms(billing_permissions);
            permissionManager.setPermissionsPerms(permissions_management);
            return Promise.resolve(permissionManager.getPerms());
        }
    } catch (error) {
        console.error('Error fetching permissions:', error);
        return Promise.reject();
    }
}

export default async (type, params) => {
    switch (type) {
        case AUTH_LOGIN:
            localStorage.setItem('token', params.access_token);
            return await fetchPermissions();
        case AUTH_LOGOUT:
            permissionManager.deletePerms();
            sessionStorage.clear();
            localStorage.clear();
            return Promise.resolve();
        case AUTH_ERROR:
            switch (params.status) {
                case 403:
                case 401:
                    permissionManager.deletePerms();
                    localStorage.clear();
                    sessionStorage.clear();
                    window.location.href = '/#/';
                    return Promise.reject();
                default:
                    return Promise.resolve(params.message);
            }
        case AUTH_GET_PERMISSIONS:
            let permissions = permissionManager.getPerms();
            if (!permissions || (typeof permissions === 'object' && Object.keys(permissions).length === 0)) {
                return fetchPermissions();
            }
            return Promise.resolve(permissions);
        case AUTH_CHECK:
            const token = localStorage.getItem("token");
            if (!token) return Promise.reject();
            try {
                return await fetchPermissions();
            } catch (error) {
                console.error("Error validating token");
                return Promise.reject();
            }
        default:
            return Promise.reject(`Unknown method: ${type}`);
    }
};
