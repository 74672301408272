import React, { useState } from "react";
import { renderIfPermissions } from "../api/permissionManager";
import PublishIcon from '@mui/icons-material/Publish';
import { useRefresh } from "react-admin";

export const AltDashboardUploadComponent = (props) => {
  const [fileReader] = useState(new FileReader());
  const clientKey = props.id;
  const token = localStorage.getItem("token");
  const API_URL = (window.frameElement && window.frameElement.getAttribute("data-api-url")) ||
                (process && process.env && process.env.REACT_APP_API_URL);
  const refresh = useRefresh();
  var file;
  var success = true;
  var image_list = [];
  var url_list;

  function successAlert() {
    if(success){
      alert("alternate dashboard logo uploaded successfully")
      refresh()
    } else {
      alert("alternate dashboard logo failed to upload")
    }
  }

  const dataURItoBlob = async (dataURI) => {
    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    var length = binary?.length || 0;
    for(var i = 0; i < length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {type: 'image/png'});
  }

  const presignedURLs = async (filename) => {
    var requestOptions = {
      method: 'PUT',
      headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
    };

    const response = await fetch(`${API_URL}/internal/clients/${clientKey}/assets/alt_dashboard_logos/${filename}`, requestOptions);
    if(response.ok) {
      var body = await response.json();
    } else {
      success = false;
    }
    try{
      return await body.urls
    } catch {
      return Array(7).fill(null)
    }
  }

  const resizeImage = async (imgData, scaleFactor, filenames) => {
    if(!Array.isArray(filenames)){
      filenames = [filenames]
    }
    var img = new Image();
    img.src = await imgData;
    await img.decode()

    var elem = document.createElement('canvas');

    elem.width = img.width * scaleFactor;
    elem.height = img.height * scaleFactor;

    var ctx = elem.getContext('2d');
    ctx.drawImage(img, 0, 0, elem.width, elem.height);

    var srcEncoded = ctx.canvas.toDataURL('image/png', 1);
  
    for (const name of filenames) {
      const url = url_list.shift();
      image_list.push({ "data": await dataURItoBlob(srcEncoded), "url": url });
    }
  }

  const handleFileUpload = (event) => {
    file = event.target.files[0];
    fileReader.onloadend = handleFileRead;
    fileReader.readAsDataURL(file);
  }

  const handleFileRead = async (event) => {
    var data = fileReader.result;

    if(file.type !== 'image/png'){
      alert("Invalid file type! Please upload a png.");
      return;
    }

    if(!confirm(`Does this information look correct?\n\nfilename: ${file.name}`)){return;}

    const originalFilename = file.name.slice(0, -4)

    url_list = await presignedURLs(originalFilename);
    
    await resizeImage(data, 1.00, [`${originalFilename}@3x`, `${originalFilename}-xxhdpi`])
    await resizeImage(data, 0.66, [`${originalFilename}@2x`, `${originalFilename}-xhdpi`])
    await resizeImage(data, 0.50, `${originalFilename}-hdpi`)
    await resizeImage(data, 0.33, [`${originalFilename}`, `${originalFilename}-mdpi`])

    await uploadImages()
  }

  const uploadImages = async () => {
    try {
      await Promise.all(image_list.map(async (image) => {
        const response = await fetch(image["url"], {
          method: 'PUT',
          body: image["data"],
          headers: {'X-Amz-acl': 'public-read'}
        });
        if (!response.ok) {
          success = false;
        }
      }));
      successAlert();
    } catch (error) {
      success = false;
      successAlert();
    }
  }

  const buttonStyle = {
     display: "inline-block",
     margin: "0 0.3em 0.3em 0",
     borderRadius: "2em",
     boxSizing: "border-box",
     textDecoration: "none",
     fontFamily: 'Roboto',
     fontWeight: "300",
     color: "#FFFFFF",
     backgroundColor: "#4eb5f1",
     textAlign: "center",
     cursor: "pointer"
  }

  const uploadBtn = <button style={buttonStyle}><label htmlFor="inputfile" style={{cursor: "pointer"}}><PublishIcon /></label></button>

  return (
    <div id="FileHandler">
      <input type="file" name="file" className="inputfile" id="inputfile" onChange={(e)=> {handleFileUpload(e); e.target.value=null }} hidden/>
      <h4>Upload {uploadBtn}</h4>
    </div>
  );

}
