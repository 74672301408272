import React, { useState } from "react";
import { CircularProgress, TextField, Button, Select, InputLabel, MenuItem, FormControl } from '@mui/material'
import { useRedirect } from "react-admin";

const apiUrl = (window.frameElement && window.frameElement.getAttribute("data-api-url")) || (process && process.env && process.env.REACT_APP_API_URL);

const min_chunk_size = 10;
const max_chunk_size = 200;
const default_chunk_size = 50;


export const RouteDifferenceGeneratorForm = (props) => {
  let [id, setId] = useState(localStorage.getItem("PRD_route_id"));
  let [chunkSize, setChunkSize] = useState(localStorage.getItem("PRD_chunk_size") || default_chunk_size);
  let [chunkingType, setChunkingType] = useState(localStorage.getItem("PRD_chunking_type") || 'two_step');
  let [loading, setLoading] = useState(false);
  let [token, setToken] = useState(localStorage.getItem("token"));

  const redirect = useRedirect();

  let submitHandler = (event) => {
    event.preventDefault();
    let valid = true
    setLoading(true);

    let requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
    };

    if (id == null || id.length == 0) {
      alert(`Please enter route ID`);
      valid = false
    }
    
    if (valid) {
      let requestUrl = `${apiUrl}/internal/routes/${id}?chunk_size=${chunkSize}&chunking_type=${chunkingType}`;

      fetch(requestUrl, requestOptions)
        .then(response => {
          if (response.ok) {
            response.json().then(resp => {
              props.setRouteFromClient(resp.geometry);
              props.setBadChunks(resp.bad_geometry_chunks);
              props.setRouteId(id);
              setLoading(false);
              redirect("/pluggable_routes/difference_map");
            });
          }
          else {
            if (response.status === 404) {
              alert(`Request failed. Status: ${response.status}. Route not found`);
            } else {
              alert(`Request failed. Status: ${response.status}.`);
            }
            setLoading(false);
          }
        })
        .catch(err => {
          alert(`Request failed. ${err}`);
          setLoading(false);
          console.log(err)
        })
    }
    else {
      setLoading(false);
    }
  }

  let idChange = (event) => {
    setId(event.target.value);
    localStorage.setItem("PRD_route_id", event.target.value)
  }
  let chunkSizeChange = (event) => {
    setChunkSize(event.target.value);
    localStorage.setItem("PRD_chunk_size", event.target.value)
  }

  let formatChunkSizeChange = (event) => {
    var target_chunk_size = event.target.value
    if (isNaN(event.target.value)) target_chunk_size = default_chunk_size
    target_chunk_size = Math.min(max_chunk_size, Math.max(target_chunk_size || default_chunk_size, min_chunk_size))
    
    setChunkSize(target_chunk_size);
    localStorage.setItem("PRD_chunk_size", target_chunk_size)
  }

  let chunkingTypeChange = (event) => {
    setChunkingType(event.target.value);
    localStorage.setItem("PRD_chunking_type", event.target.value)
  }

  const RenderSubmitButton = () => {
    if (loading == false) {
      return (
        <div style={inputStyle}>
          <Button type='submit' variant="contained" color="primary">Send Request</Button>
        </div>
      );
    }
    else {
      return (<div style={{ marginTop: "1rem" }}><CircularProgress />Loading route onto Differences Map</div>);
    }
  }

  const inputStyle = {
    margin: "8px",
    width: "195px"
  }

  return (
    <div >
      <h2>Pluggable Route Difference Generator</h2>
      <p>Find sections along routes which are causing errors</p>
      <p>Submit Route ID: </p>
      <ul>
        <li>Once loading is completed you can view the route and any errors in the Differences Map tab</li>
      </ul>

        <form onSubmit={submitHandler}>
          <div style={inputStyle}>
            <TextField
              variant="outlined"
              label="Route ID"
              value={id}
              size="small"
              onChange={idChange} />
          </div>
          <div style={inputStyle}>
            <TextField
              variant="outlined"
              label="Chunk Size"
              value={chunkSize}
              size="small"
              onChange={chunkSizeChange}
              onBlur={formatChunkSizeChange} />
          </div>
          <FormControl fullWidth style={inputStyle}>
            <InputLabel variant="outlined" id="chunking-type-label">Chunking Type</InputLabel>
            <Select
              labelId="chunking-type-label"
              variant="outlined"
              label="Chunking Type"
              value={chunkingType}
              onChange={chunkingTypeChange}
            >
              <MenuItem value={'default'}>Original</MenuItem>
              <MenuItem value={'binary_search'}>Binary Search</MenuItem>
              <MenuItem value={'two_step'}>Nolan's Two Step</MenuItem>
            </Select>
          </FormControl>
        </form>
      <form onSubmit={submitHandler}>
        {RenderSubmitButton()}
      </form>
    </div>
  );
}