import { ReferenceManyField, Datagrid, TextField, DateField, useRecordContext, SimpleShowLayout } from "react-admin";
import get from 'lodash/get';
import JSONPretty from 'react-json-pretty';

export const ChangelogShow = ({...props}) => {
  const record = useRecordContext(props);
  var changes = get(record, "after");
  var before = get(record, "before");

  return (
    <SimpleShowLayout>
      <JSONPretty data={changes} label="Changes" themeClassName="custom-json-pretty"></JSONPretty>
      <JSONPretty data={before} label="Before" themeClassName="custom-json-pretty"></JSONPretty>
    </SimpleShowLayout> 
  );
}

export const Changelog = (props) => {
  return (
    <ReferenceManyField label="Changelog" reference="changelog" target="id" filter={{ from: props.from }}>
      <Datagrid label="Changelog" bulkActionButtons={false} expand={<ChangelogShow {...props}/>} style={{tableLayout: 'fixed'}}>
          <TextField source="superuser_email" label="Superuser Email"/>
          <DateField source="occurred_at" showTime={true} label="Occurred at"/>
      </Datagrid>
    </ReferenceManyField>
  );
}

