import React, { useState } from "react";
import { Button, Typography, Divider } from "@mui/material";
import { DialogContent, DialogActions, Dialog } from '@mui/material';
import { useNotify } from 'react-admin';

export const ResendInviteButton = ({record, ...props}) => {
  const notify = useNotify();
  const [open, setOpen] = useState(false);

  const API_URL = (window.frameElement && window.frameElement.getAttribute("data-api-url")) || (process && process.env && process.env.REACT_APP_API_URL);

  const resendBackendUserInvite = (email) => {
    var requestOptions = {
      method: 'PUT',
      headers: { 'Authorization': `Bearer ${localStorage.getItem("token")}`, 'Content-Type': 'application/json' }
    };

    var requestUrl = `${API_URL}/internal/backend_users/resend_invite/${email}`

    fetch(requestUrl, requestOptions)
      .then((response) => {
        if (response.ok) {
          response.json().then((resp) => {
            notify(`Invitation has been succesfully resent for ${email}`, {
              type: "info",
            });
          });
        } else {
          notify(`${email}'s invite failed. Status: ${response.status}`, {
            type: "warning",
          });
        }
      })
      .catch((error) => {
        notify(`Request failed. ${error}`, { type: "warning" });
      });
  };
    
  const inviteStyles = {
    width: "300px",
    display: "flex",
    flexDirection: "column",
    paddingTop: "15px",
    paddingBottom: "20px"
  };
  
  const labelStyles = {
    color: "rgba(0, 0, 0, 0.54)",
    marginBottom: "8px"
  };

  const handleInvite = (rec) => {
    resendBackendUserInvite(rec.email)
  };

  const ConfirmationDialog = (props) => {
    const handleCancel = () => {
      setOpen(false)
      notify((`Invitation has been canceled for ${record.email}`), {type: 'info'})
    };

    const handleOk = () => {
      setOpen(false)
      handleInvite(record)
    };

    return (
      <Dialog maxWidth="sm" open={open}>
        <DialogContent>
          <Typography variant="subtitle1">{`Are you sure you want to resend an invitation to ${record.email}?`}</Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus aria-label="cancel-invite" onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button aria-label="confirm-invite" onClick={handleOk} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <>
    <Divider style={{marginTop: "8px"}}/>
    <div style={inviteStyles} > 
        <Typography variant="overline" style={labelStyles}>Resend Invite for this backend user:</Typography>
        <div>
          <Button color="primary" size="small" variant="outlined" onClick={() => setOpen(true)} aria-label={"resend-button"}>Send</Button>
        </div>
    </div>
    <ConfirmationDialog {...props}/>
    </>
  )
}