import React, {
  useState
} from "react";

import Button from '@mui/material/Button';
import { useNotify, useRecordContext, useRefresh,  useUpdate} from 'react-admin';
import { styled } from '@mui/system';
import Icon from '@mui/icons-material/PowerSettingsNew';

const PowerSettingsIcon = Icon;

const StyledButton = styled(Button)(({ theme }) => ({
  fontWeight: 'bold',
  background: 'red',
  color: 'white',
}));

const ButtonContainer = styled('div')({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
});

const DeactivateClientButton = () => {
  const record = useRecordContext();
  const clientID = record.id;
  const notify = useNotify();
  const refresh = useRefresh();

  const [update, { isLoading }] = useUpdate(
      'clients',
      {
        id: clientID,
        data: { active: false },
        previousData: { }
      },
      {
        onSuccess: () => {
            refresh();
            notify('Client Deactivated', 'success');
        },
        onFailure: error => {
            refresh();
            notify(`${error}`, 'warning');

        }
      }
  );
  const handleConfirm = () => {
      var response = prompt("Are you sure you want to deactivate this client? This will turn off the customer. Users will be logged out, unable to log in to the app or platform dashboard, and will be unable to use our API. The group key for this client will not be preserved. If you decide at a later time to reactivate this client, the group key must be re-entered.\nPlease enter the client key exactly to deactivate this client.")
      while (response !== null && response !== clientID) {
        var response = prompt("Client key was entered incorrectly.\nPlease enter the client key exactly.")
      }
      if (response === clientID) {
          update();
      }
  };

  return (
    <ButtonContainer>
      <StyledButton label="Deactivate Client" variant="contained" onClick={handleConfirm} startIcon={<PowerSettingsIcon/>}>
          Deactivate Client
      </StyledButton>
    </ButtonContainer>
  );
}


const ActivateBillableClientButton = () => {
  const record = useRecordContext();
  const clientID = record.id;
  const notify = useNotify();
  const refresh = useRefresh();
  const [update, { isLoading }] = useUpdate(
      'clients',
      {
        id: clientID,
        data: { active: true, is_usage_billable: true },
        previousData: { }

      },
      {
          onSuccess: () => {
              refresh();
              notify('Client Activated with Billing Enabled', 'success');

          },
          onFailure: error => {
              refresh();
              notify(`${error}`, 'warning');

          }
      }
  );
  const handleConfirm = () => {
    var response = prompt("When reactivated, this client WILL be billable.\nPlease enter the client key exactly to activate this client.")
    while (response !== null && response !== clientID) {
      var response = prompt("Client key was entered incorrectly.\nPlease enter the client key exactly.")
    }
    if (response === clientID) {
        update();
    }
  };

  return (
          <Button label="Activate Billable Client" variant="contained" color="primary" onClick={handleConfirm} startIcon={<PowerSettingsIcon/>}>
              Activate Billable Client
          </Button>
  );
}

const ActivateNonBillableClientButton = () => {
  const record = useRecordContext();
  const clientID = record.id;
  const [open, setOpen] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const [update, { isLoading }] = useUpdate(
      'clients',
      {
        id: clientID,
        data: { active: true}, 
        previousData: { }
      },
      {
          onSuccess: () => {
              refresh();
              notify('Client Activated with Billing Disabled', 'success');

          },
          onFailure: error => {
              refresh();
              notify(`${error}`, 'warning');

          }
      }
  );
  const handleConfirm = () => {
    var response = prompt("When reactivated, this client WILL NOT be billable.\nPlease enter the client key exactly to activate this client.")
    while (response !== null && response !== clientID) {
      var response = prompt("Client key was entered incorrectly\nPlease enter the client key exactly.")
    }
    if (response === clientID) {
        update();
    }
  };

  return (
          <Button label="Activate Non-Billable Client" variant="contained" color="primary" onClick={handleConfirm} startIcon={<PowerSettingsIcon/>}>
              Activate Non-Billable Client
          </Button>
  );
}

export {
  DeactivateClientButton,
  ActivateBillableClientButton,
  ActivateNonBillableClientButton
}