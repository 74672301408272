import React, { useCallback, useEffect, useMemo, useState } from "react";
import { List, TopToolbar, Button, useGetList, useUpdate, useNotify, useRefresh, useCreate, Create } from "react-admin"
import { CreatePermissions } from "../components/PermissionsButtons";
import { Checkbox, CircularProgress, FilledInput, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { AddCircleOutline, ChevronRight, ExpandMore, ModeEdit } from "@mui/icons-material";

const AddResource = ({ row, handleSetNewResource }) => {
  const [filteredResources, setFilteredResources] = useState([]);
  const [resource, setResource] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [read, setRead] = useState(false);
  const [write, setWrite] = useState(false);
  const notify = useNotify();
  const { data, isPending, error } = useGetList(
    'internal_permissions',
    {
      meta: "getResources"
    }
  );
  // Handle null permissions by using an empty array as a fallback
  const existingResourceIds = useMemo(() => {
    if (row.permissions) {
      return row.permissions.map(permission => permission.id);
    } else if (data) {
      return data;
    }
    return []; // Return an empty array if both are undefined or null
  }, [row.permissions, data]);

  useEffect(() => {
    // Only update filteredResources if data or existingResourceIds are defined
    if (data && existingResourceIds.length > 0) {
      const filtered = data.filter(resource => !existingResourceIds.includes(resource.value));
      setFilteredResources(filtered);
    }
    if (error) {
      notify(`Error loading available resources:\n ${JSON.stringify(error)}`, { type: 'error' });
    }
  }, [data, existingResourceIds]);


  const handleSelectChange = (event) => {
    const selectedResource = event.target.value;
    setResource(selectedResource);
    setIsActive(true);
    let resourceToAdd = {
      resource: selectedResource,
      group: row.id,
      is_active: true,
      read: read,
      write: write
    }
    handleSetNewResource(resourceToAdd)
  };

  const handlePermsChange = (perm) => {
    let newIsActive = isActive;
    let newRead = read;
    let newWrite = write;
    switch (perm) {
      case 'is_active':
        newIsActive = !isActive;
        break;
      case 'read':
        newRead = !read;
        !newRead ? newWrite = false : ''
        break;
      case 'write':
        newWrite = !write;
        newWrite ? newRead = true : ''
        break;
    }
    setIsActive(newIsActive);
    setRead(newRead);
    setWrite(newWrite);
    const resourceToAdd = {
      resource: resource,
      group: row.id,
      is_active: newIsActive,
      read: newRead,
      write: newWrite
    };
    handleSetNewResource(resourceToAdd);
  };

  return (
    <TableRow selected>
      <TableCell>
        {isPending ? (
          <CircularProgress />
        ) : (
          <>
            {filteredResources.length > 0 ? (
              <FormControl style={{ width: '12em' }}>
                <InputLabel id="select-resource">Select Resource</InputLabel>
                <Select
                  labelId="select-resource"
                  id="resource-select"
                  value={resource}
                  label="Select Resource"
                  onChange={(e) => handleSelectChange(e)}
                >
                  {filteredResources.map((resource, i) => (
                    <MenuItem key={i} value={resource.value}>{resource.value}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <Typography>No resources available</Typography>
            )}
          </>
        )}
      </TableCell>
      <TableCell>
        <Checkbox checked={isActive} onChange={() => handlePermsChange('is_active')} />
      </TableCell>
      <TableCell>
        {resource === "full_client_impersonation" ? <div id="hidden_checkbox" hidden>Read Disabled</div> : <Checkbox checked={read} onChange={() => handlePermsChange('read')} />}
      </TableCell>
      <TableCell>
        <Checkbox checked={write} onChange={() => handlePermsChange('write')} />
      </TableCell>
    </TableRow>
  );
};

const Resources = ({ row, isEditMode, editValues, handleCheckboxChange, handleSelectAll, isAddMode, addRowId, handleSetNewResource }) => {
  const [selectAllActive, setSelectAllActive] = useState(false);
  const [selectAllRead, setSelectAllRead] = useState(false);
  const [selectAllWrite, setSelectAllWrite] = useState(false);

  useEffect(() => {
    if (row.permissions?.length > 0) {
      const allActive = row.permissions.every(permission => editValues[permission.id]?.is_active ?? permission.perms.is_active);
      const allRead = row.permissions.every(permission => editValues[permission.id]?.read ?? permission.perms.read);
      const allWrite = row.permissions.every(permission => editValues[permission.id]?.write ?? permission.perms.write);
      setSelectAllActive(allActive);
      setSelectAllRead(allRead);
      setSelectAllWrite(allWrite);
    }
  }, [row.permissions, editValues]);

  const handleSelectAllButton = (field) => {
    const newState = !{
      is_active: selectAllActive,
      read: selectAllRead,
      write: selectAllWrite
    }[field];

    if (field === 'is_active') setSelectAllActive(newState);
    if (field === 'read') setSelectAllRead(newState);
    if (field === 'write') setSelectAllWrite(newState);

    if (field === 'write') {
      // If write is selected, ensure read is also selected
      if (newState) {
        handleSelectAll(row.permissions, 'read', true);
      }
    }
    if (field === 'read') {
      // If read is deselected, ensure write is also deselected
      if (!newState) {
        handleSelectAll(row.permissions, 'write', false);
      }
    }

    handleSelectAll(row.permissions, field, newState);
  };

  const fullClientCheck = (editMode, permission) => {
    if (editMode && permission.id !== 'full_client_impersonation') {
      return <Checkbox
        checked={editValues[permission.id]?.read ?? permission.perms.read}
        onChange={handleCheckboxChange(permission.id, 'read')}
      />
    }
    if (!editMode && permission.id !== 'full_client_impersonation') {
      return <Checkbox checked={permission.perms.read} disabled />
    }
    else return <div id="hidden_checkbox" hidden>Read Disabled</div>
  }

  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Resource</TableCell>
            <TableCell>
              <Typography style={{ fontSize: '0.875rem' }}>Is Active?</Typography>
              {isEditMode && <Button style={{ marginLeft: '-1rem', fontSize: 'smaller' }} label={selectAllActive ? "Deselect All" : "Select All"} onClick={() => handleSelectAllButton('is_active')} />}
            </TableCell>
            <TableCell>
              <Typography style={{ fontSize: '0.875rem' }}>Read</Typography>
              {isEditMode && <Button style={{ marginLeft: '-1rem', fontSize: 'smaller' }} label={selectAllRead ? "Deselect All" : "Select All"} onClick={() => handleSelectAllButton('read')} />}
            </TableCell>
            <TableCell>
              <Typography style={{ fontSize: '0.875rem' }}>Write</Typography>
              {isEditMode && <Button style={{ marginLeft: '-1rem', fontSize: 'smaller' }} label={selectAllWrite ? "Deselect All" : "Select All"} onClick={() => handleSelectAllButton('write')} />}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isAddMode && addRowId === row.id && (
            <AddResource row={row} handleSetNewResource={handleSetNewResource} />
          )}
          {row.permissions && row.permissions.length > 0 ?
            (row.permissions.map(permission => (
              <TableRow key={permission.id}>
                <TableCell>{permission.id}</TableCell>
                <TableCell>
                  {isEditMode ? (
                    <Checkbox
                      checked={editValues[permission.id]?.is_active ?? permission.perms.is_active}
                      onChange={handleCheckboxChange(permission.id, 'is_active')}
                    />
                  ) : (
                    <Checkbox checked={permission.perms.is_active} disabled />
                  )}
                </TableCell>
                <TableCell>
                  {fullClientCheck(isEditMode, permission)}
                </TableCell>
                <TableCell>
                  {isEditMode ? (
                    <Checkbox
                      checked={editValues[permission.id]?.write ?? permission.perms.write}
                      onChange={handleCheckboxChange(permission.id, 'write')}
                    />
                  ) : (
                    <Checkbox checked={permission.perms.write} disabled />
                  )}
                </TableCell>
              </TableRow>
            ))) : (<TableRow>
              <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                This group currently does not have any permissions.
              </TableCell>
            </TableRow>)}
        </TableBody>
      </Table>
    </div>
  );
};

const CustomDatagrid = ({ rows, expanded, setExpanded, onToggleExpand }) => {
  const [areAllExpanded, setAreAllExpanded] = useState(false);
  const [error, setError] = useState('')
  const [editRowId, setEditRowId] = useState(null);
  const [addRowId, setAddRowId] = useState(null);
  const [isAddMode, setIsAddMode] = useState(false);
  const [editValues, setEditValues] = useState({});
  const [initialValues, setInitialValues] = useState({});
  const [newResource, setNewResource] = useState({});
  const [update, { isPending }] = useUpdate();
  const [create] = useCreate();
  const notify = useNotify();
  const refresh = useRefresh();

  const handleToggleExpand = (rowId) => {
    if (expanded[rowId]) {
      setEditRowId(null);
      setAddRowId(null);
    }
    onToggleExpand(rowId);
  };

  const handleToggleAll = useCallback(() => {
    const shouldExpand = !areAllExpanded;
    setAreAllExpanded(shouldExpand);
    setExpanded(shouldExpand ? rows.reduce((acc, { id }) => ({ ...acc, [id]: true }), {}) : {});
    if (shouldExpand) {
      setEditRowId(null);
      setAddRowId(null)
    };
  }, [areAllExpanded, rows, setExpanded]);

  const handleEditClick = (rowId) => {
    if (!expanded[rowId]) onToggleExpand(rowId);
    setEditRowId(rowId);
    const row = rows.find(({ id }) => id === rowId);
    const initialData = row.permissions.reduce((acc, { id, perms }) => ({
      ...acc,
      [id]: { is_active: perms.is_active, read: perms.read, write: perms.write }
    }), {});
    setInitialValues(initialData);
    setEditValues(initialData);
  };

  const handleAddResourceClick = (rowId) => {
    if (!expanded[rowId]) onToggleExpand(rowId);
    setAddRowId(rowId);
    setIsAddMode(true);
  };

  const handleSetNewResource = (resourceToAdd) => { setNewResource(resourceToAdd) }

  const handleSave = async (rowId) => {
    if (isAddMode) {
      const { group, resource, is_active, read, write } = newResource;
      await create(
        'internal_permissions',
        { data: { group: group, resource: resource, read: read, write: write, is_active: is_active } },
        {
          onSuccess: () => {
            notify(`Resource ${resource} successfully added to ${group}`, { type: 'success' })
            refresh();
          },
          onError: (error) => notify(`Failed to add ${resource} to ${group}: ${JSON.stringify(error)}`, { type: 'error' })
        }
      )
      setIsAddMode(false);
      setAddRowId(null);
      setEditValues({});
    }
    else {
      let errorMessage = false;
      let resourcesWithError = []
      const row = rows.find((r) => r.id === rowId);
      const updatePromises = Object.keys(editValues).map(async resourceId => {
        const editValue = editValues[resourceId];
        const initialValue = initialValues[resourceId] || {};
        const hasActiveChanged = editValue.is_active !== initialValue.is_active;
        const hasReadOrWriteChanged = editValue.read !== initialValue.read || editValue.write !== initialValue.write;

        if (hasReadOrWriteChanged) {
          if ((editValue.read || editValue.write) && !editValue.is_active) {
            errorMessage = true;
            resourcesWithError.push(resourceId)
            return;
          }
        }
        try {
          if (hasReadOrWriteChanged) {
            await update(
              'internal_permissions',
              { data: { resource: resourceId, read: editValue.read, write: editValue.write, group: row.id, useActive: false } },
              {
                onSuccess: () => {
                  notify(`Resource permissions successfully updated`, { type: 'success' })
                  refresh();
                },
                onError: (error) => {
                  notify(`Failed to update permission(s) check console.`, { type: 'error' }),
                    console.error(`Failed to update ${resourceId}: ${JSON.stringify(error)}`)
                }
              }
            );
          }

          if (hasActiveChanged) {
            await update(
              'internal_permissions',
              { data: { resource: resourceId, is_active: editValue.is_active, group: row.id, useActive: true } },
              {
                onSuccess: () => {
                  notify(editValue.is_active ? `Resource successfully activated` : `Resource successfully deactivated`, 'success');
                  refresh();
                },
                onError: (error) => notify(`Failed to update ${resourceId}: ${JSON.stringify(error)}`, { type: 'error' })
              }
            );
          }
        } catch (error) {
          notify(`Failed to update ${resourceId}: ${JSON.stringify(error)}`, { type: 'error' });
        }
      });

      await Promise.all(updatePromises);
      if (errorMessage) {
        setError(`You must activate the following resources before setting their permissions: \n${resourcesWithError}`);
      } else {
        setError('');
        setEditRowId(null);
        setEditValues({});
      }
    }
  };

  const handleCancel = () => {
    setError('')
    setEditValues(initialValues);
    setEditRowId(null);
    setAddRowId(null);
    setIsAddMode(false);
  };

  const handleCheckboxChange = (resourceId, field) => (event) => {
    setEditValues((prevValues) => {
      const updatedResource = { ...prevValues[resourceId], [field]: event.target.checked };
      if (field === 'write' && event.target.checked) updatedResource.read = true;
      if (field === 'read' && !event.target.checked) updatedResource.write = false;
      return { ...prevValues, [resourceId]: updatedResource };
    });
  };

  const handleSelectAll = (permissions, field, checked) => {
    setEditValues(prevValues => {
      const updatedValues = { ...prevValues };
      permissions.forEach(permission => {
        updatedValues[permission.id] = {
          ...updatedValues[permission.id],
          [field]: checked
        };
      });
      return updatedValues;
    });
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <IconButton onClick={handleToggleAll}>
                  {areAllExpanded ? <ExpandMore /> : <ChevronRight />}
                </IconButton>
              </TableCell>
              <TableCell>Group Name</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <React.Fragment key={row.id}>
                <TableRow>
                  <TableCell>
                    <IconButton onClick={() => handleToggleExpand(row.id)}>
                      {expanded[row.id] ? <ExpandMore /> : <ChevronRight />}
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">{row.id}</Typography>
                  </TableCell>
                  <TableCell>{editRowId === row.id && <Typography style={{ fontSize: 'smaller', color: '#E52D2D' }} >{error}</Typography>}</TableCell>
                  <TableCell align="right">
                    {((editRowId === row.id) || addRowId === row.id) ? (
                      <>
                        <Button onClick={() => handleSave(row.id)} color="primary" disabled={isPending} label="Save" />
                        <Button onClick={handleCancel} color="secondary" disabled={isPending} label="Cancel" />
                      </>
                    ) : (
                      <>
                        <IconButton key={`add-${index}`} color="primary" onClick={() => handleAddResourceClick(row.id)}>
                          <AddCircleOutline />
                        </IconButton>
                        {row.permissions && row.permissions.length > 0 && (
                          <IconButton key={`edit-${index}`} onClick={() => handleEditClick(row.id)} color="primary">
                            <ModeEdit />
                          </IconButton>
                        )}
                      </>
                    )}
                  </TableCell>
                </TableRow>
                {expanded[row.id] && (
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Resources
                        row={row}
                        isEditMode={editRowId === row.id}
                        editValues={editValues}
                        handleCheckboxChange={handleCheckboxChange}
                        handleSelectAll={handleSelectAll}
                        setEditRowId={setEditRowId}
                        isAddMode={isAddMode}
                        addRowId={addRowId}
                        handleSetNewResource={handleSetNewResource}
                      />
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const CustomPermissionsList = () => {
  const [expanded, setExpanded] = useState({});
  const { data, isLoading, error } = useGetList('internal_permissions');
  const [filterId, setFilterId] = useState('');

  // Filter data based on the filterId
  const filteredData = data ? data.filter(item => item.id === filterId) : [];

  const handleToggleExpand = useCallback((id) => {
    setExpanded((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  }, []);

  const handleExpandAll = () => {
    if (data) {
      const allIds = data.map(row => row.id);
      setExpanded(allIds.reduce((acc, id) => ({ ...acc, [id]: true }), {}));
    }
  };

  const handleFilterChange = (event) => {
    setFilterId(event.target.value);
  };

  return (
    <>
      <div>
        <FormControl variant="filled" sx={{ display: 'inline-block' }}>
          <InputLabel htmlFor="group-filter" style={{ marginLeft: '1em' }}>Search By Group Name</InputLabel>
          <FilledInput id="group-filter" onChange={handleFilterChange} style={{ width: '15em', marginLeft: '1em', marginRight: '1em' }} />
          <CreatePermissions />
        </FormControl>
      </div>
      {isLoading && <h2 align='center' style={{ margin: 35, padding: 10 }}><CircularProgress /></h2>}
      {!data && !isLoading && <h2 align='center' style={{ margin: 35, padding: 10 }}>No permissions exist</h2>}
      {filteredData.length > 0 ?
        <CustomDatagrid
          rows={filteredData}
          expanded={expanded}
          setExpanded={setExpanded}
          onExpandAll={handleExpandAll}
          onToggleExpand={handleToggleExpand}
        /> : (data && <CustomDatagrid
          rows={data}
          expanded={expanded}
          setExpanded={setExpanded}
          onExpandAll={handleExpandAll}
          onToggleExpand={handleToggleExpand}
        />)
      }
      {error && <h2 align='center' style={{ margin: 35, padding: 10 }}>There was an error loading permissions</h2>}
    </>
  );
};

export const PermissionsList = (props) => (
  <List {...props} pagination={false} exporter={false} >
    <CustomPermissionsList />
  </List>
)