import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/system';

// Access the theme and pass it to Tabs class component
const TabsWithTheme = (props) => {
  const theme = useTheme();
  return <Tabs {...props} theme={theme} />;
};

class Tab extends Component {
  static propTypes = {
    activeTab: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  onClick = () => {
    const { label, onClick } = this.props;
    onClick(label);
  };

  render() {
    const {
      onClick,
      props: { activeTab, label, theme },
    } = this;

    // Conditionally apply active class and styles
    const isActive = activeTab === label;
    const className = `tab-list-item${isActive ? ' tab-list-active' : ''}`;

    return (
      <li
        className={className}
        onClick={onClick}
        style={{
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.background.default,
          fontFamily: theme.palette.mode === 'dark' ? 'Gabarito, Tahoma, sans-serif' : '',
          fontSize: theme.palette.mode === 'dark' ? '0.875rem' : '',
          fontWeight: theme.palette.mode === 'dark' ? 700 : '',
          borderBottom: isActive ? `4px solid ${theme.palette.primary.main}` : 'none',
          borderTop: 'none',
          borderLeft: 'none',
          borderRight: 'none',
        }}
      >
        {label}
      </li>
    );
  }
}

class Tabs extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
    theme: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      activeTab: props.children[0].props.label,
    };
  }

  handleChildTabChange = () => {
    this.props.onTabChange();
  };

  onClickTabItem = (tab) => {
    this.setState({ activeTab: tab });
    this.handleChildTabChange();
  };

  render() {
    const {
      onClickTabItem,
      props: { children, mapClick, theme },
      state: { activeTab },
    } = this;

    return (
      <div className="tabs">
        <ol className="tab-list">
          {children.map((child) => {
            const { label } = child.props;

            return (
              <Tab
                activeTab={mapClick ? mapClick : activeTab}
                key={label}
                label={label}
                onClick={onClickTabItem}
                theme={theme}
              />
            );
          })}
        </ol>
        <div className="tab-content">
          {mapClick
            ? children.map((child) => {
                if (child.props.label !== mapClick) return null;
                return child.props.children;
              })
            : children.map((child) => {
                if (child.props.label !== activeTab) return null;
                return child.props.children;
              })}
        </div>
      </div>
    );
  }
}

export default TabsWithTheme;
