import React from 'react';
import { Card, CardContent, Typography, Container } from '@mui/material';
import logo from '../resources/img/IA.svg'

export const LandingPage = () => {
    const userEmail = localStorage.getItem('user_email');
    
    // Extract first and last names from the email
    const getFirstName = (email) => {
        if (!email) return '';
        const [firstPart] = email.split('@');
        const [firstName] = firstPart.split('.');
        return `${firstName.charAt(0).toUpperCase() + firstName.slice(1)}`;
    };

    return (
        <div>
                <Card style={{ textAlign: 'center', height:'100vh' }}>
                    <CardContent style={{marginTop:'10vh'}}>
                        <Typography variant="h4" style={{ marginTop: '1em', marginBottom: '1em' }} gutterBottom>
                            {`Welcome to Internal Admin, ${getFirstName(userEmail)}!`}
                        </Typography>
                        <div>
                            <img src={logo} height="100" alt="Logo" />
                        </div>
                        <Typography variant="body1" style={{ marginTop: '2em', marginBottom: '2em' }} paragraph>
                            You can access all of your available features and functionalities from the Menu on the left.
                        </Typography>
                    </CardContent>
                </Card>
        </div>
    );
};
