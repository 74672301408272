import React from "react";
import { styled } from '@mui/system';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Root = styled('div')(({ theme }) => ({
  paddingLeft: '2em',
  paddingRight: '2em',
}));

const Heading = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(15),
  fontWeight: theme.typography.fontWeightRegular,
}));

const Subheading = styled('h4')(({ theme }) => ({
  marginBottom: '0.5em',
  marginTop: '1.5em',
}));

const Subgroup = styled('div')(({ theme }) => ({
  marginBottom: '1.5em',
}));

const DocItem = (props) => {
  let title = props.title || ''
  let body = props.children || ''

  return(
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
          <Heading>{title}</Heading>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {body}
          </Typography>
        </AccordionDetails>
      </Accordion>
  )
}

const DocGroup = (props) => {
  let title = props.title || ''
  let children = props.children || ''
  let width = props.width || '35em'

  return(
    <Root style={{width: width}}>
      <h1>{title}</h1>
      {children}
    </Root>
  )
}

const DocSubgroup = (props) => {
  let title = props.title || ''
  let children = props.children || ''

  return(
    <Subgroup>
      <Subheading>{title}</Subheading>
      {children}
    </Subgroup>
  )
}

export {
  DocItem, 
  DocGroup,
  DocSubgroup
}