import React, { useState } from "react";
import { Button, Typography, Divider } from "@mui/material";
import { DialogContent, DialogActions, Dialog } from '@mui/material';
import { useNotify, useRefresh, useUpdate } from 'react-admin';

export const ActiveButton = ({record, ...props}) => {
    const notify = useNotify();
    const [Activate] = useUpdate();
    const [Deactivate] = useUpdate();
    const refresh = useRefresh();
    const [open, setOpen] = useState(false);

    const switcher = (record) => {
        if(record.active==false){
            Activate( "backend_users", { id: record.email, data: { active: true }}, {
                onSuccess: () => {
                  refresh();
                  notify(`Active status for ${record.email} changed`, 'success')
                },
                onFailure: error => {
                  notify(`Failed to change active status for ${record.email}: ${error}`, 'warning')
                }
            })
        }else{
            Deactivate( "backend_users", { id: record.email, data: { active: false }}, {
                onSuccess: () => {
                  refresh();
                  notify(`${record.email} changed`, 'success')
                },
                onFailure: error => {
                  notify(`Failed to change active status for ${record.email}: ${error}`, 'warning')
                }
            })
        }  
    }

    const dividerStyle = {
    width: "300px",
    display: "flex",
    flexDirection: "column",
    paddingTop: "15px",
    paddingBottom: "20px"
    };

    const labelStyles = {
    color: "rgba(0, 0, 0, 0.54)",
    marginBottom: "8px"
    };

    const ConfirmationDialog = (props) => {
        const handleCancel = () => {
            setOpen(false)
            notify((`User ${record.email}'s active status will not change`), {type: 'info'})
        };

        const handleOk = () => {
            switcher(record)
            setOpen(false)
        };

        return (
            <Dialog maxWidth="sm" open={open}>
            <DialogContent>
                <Typography variant="subtitle1">{`Are you sure you want to change the active status for ${record.email}?`}</Typography>
            </DialogContent>
            <DialogActions>
                <Button autoFocus aria-label="cancel-change" onClick={handleCancel} color="primary">
                Cancel
                </Button>
                <Button aria-label="confirm-change" onClick={handleOk} color="primary">
                Ok
                </Button>
            </DialogActions>
            </Dialog>
        );
    }
    if(record.active==true){
        return (
            <>
            <Divider style={{marginTop: "8px"}}/>
            <div style={dividerStyle} > 
                
                <Typography variant="overline" style={labelStyles}>Deactivate Unconfirmed User:</Typography>
                <div>
                    <Button color="primary" size="small" variant="outlined" key="deactivation_sequence" onClick={() => setOpen(true)} aria-label={"deactivation-button"}>Deactivate User</Button>
                </div>
            </div>
            <ConfirmationDialog {...props}/>
            </>
        )
    }
    else{
        return (
            <>
            <Divider style={{marginTop: "8px"}}/>
            <div style={dividerStyle} > 
                
                <Typography variant="overline" style={labelStyles}>Activate User:</Typography>
                <div>
                    <Button color="primary" size="small" variant="outlined" key="activation_sequence" onClick={() => setOpen(true)} aria-label={"activation-button"}>Activate</Button>
                </div>
            </div>
            <ConfirmationDialog {...props}/>
            </>
        )
    }
    
}