import {AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_GET_PERMISSIONS, AUTH_CHECK} from 'react-admin'

import permissionManager from './permissionManager';

const fetchPermissions = async () => {
  const requestUrl = `/internal/administrators/me/permissions`;
  const requestOptions = {
    method: 'GET',
    headers: {'Content-Type': 'application/json' }
  };

  const response = await fetch(requestUrl, requestOptions);
  const user_permissions = await response.json();
    
  let platform_permissions = null;
  let billing_permissions = null;
  let permissions_management = null;
  let granular_permissions = new Map();

  if (user_permissions === undefined) {
    alert('Request Failed: Could not fetch user permissions')
    return Promise.reject()
  };

  // Handle new granular permissions format
  granular_permissions = new Map(Object.entries(user_permissions));
  if (granular_permissions.size > 10) {
      // Store granular permissions
      const granular_permissions_obj = Object.fromEntries(granular_permissions);
      localStorage.setItem('granular_permissions', JSON.stringify(granular_permissions_obj));
      return Promise.resolve(granular_permissions);
  }

  // Handle old permissions format (fallback)
  if (user_permissions.read_only === true && user_permissions.super_admin === false) platform_permissions = 'read_only';
  if (user_permissions.super_admin === true) platform_permissions = 'full';
  if (user_permissions.billing_admin === false) billing_permissions = 'none';
  if (user_permissions.billing_admin === true) billing_permissions = 'full';
  if (user_permissions.permissions_admin === true) permissions_management = 'full';
  if (platform_permissions === null) {
    alert('Authentication failed: Insufficient user permissions')
    return Promise.reject()
  };


  permissionManager.setPerms(platform_permissions);
  permissionManager.setBillingPerms(billing_permissions);
  permissionManager.setPermissionsPerms(permissions_management);
  return {platform: platform_permissions, billing: billing_permissions, permissions_management: permissions_management}
}

export default (type, params) => {
  if (process.env.REACT_APP_TESTING !== 'true') return Promise.reject()

  if (type === AUTH_LOGIN) {
    return fetchPermissions();
  }
  if (type === AUTH_LOGOUT) {
    return Promise.resolve();
  }
  if (type === AUTH_ERROR) {
    // catch errors from data Provider
    // 401s and 403s require logout
    // everything else can just display an error message
    switch (params.status) {
      case 403:
        if(!window.location.href.endsWith('login')){
          permissionManager.deletePerms();
          localStorage.clear();
          window.location.href = '/#/login'
        }
        return Promise.resolve(params.message);
      case 401:
            return Promise.reject(params.message);
      default:
            return Promise.resolve(params.message);
    }
  }
  if (type === AUTH_GET_PERMISSIONS) {
      let permissions = fetchPermissions()
      return Promise.resolve(permissions);
  }
  if (type === AUTH_CHECK) {
    return Promise.resolve()
  }
  return Promise.reject("Unknown method");
};
