import { useState } from "react";
import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { DifferencesMap } from "../components/DifferencesMap";
import { RouteDifferenceGeneratorForm } from "../components/RouteDifferenceGeneratorForm";
import { BadRouteFinderForm } from "../components/BadRouteFinderForm";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const PluggableRoutesFormView = (props) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let [badChunks, setBadChunks] = useState({});
  let [routeFromClient, setRouteFromClient] = useState({});
  let [routeId, setRouteId] = useState("");
  let [routeUpload, setRouteUpload] = useState({});

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Route Finder" {...a11yProps(0)} />
          <Tab label="Route Difference Generator" {...a11yProps(1)} />
          <Tab label="Differences Map" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel path="route_finder" value={value} index={0}>
        <BadRouteFinderForm forceRender={true} setBadChunks={setBadChunks} setRouteFromClient={setRouteFromClient} setRouteId={setRouteId} />
      </TabPanel>
      <TabPanel path="difference_generator" value={value} index={1}>
        <RouteDifferenceGeneratorForm badChunks={badChunks} setBadChunks={setBadChunks} routeFromClient={routeFromClient} setRouteFromClient={setRouteFromClient} setRouteId={setRouteId} />
      </TabPanel>
      <TabPanel path="differences_map" value={value} index={2}>
        <DifferencesMap badChunks={badChunks} routeFromClient={routeFromClient} routeId={routeId} routeUpload={routeUpload}/>
      </TabPanel>
    </Box>
  );
}