import { Box } from "@mui/material";
import { DocGroup, DocItem } from "../components/DocFields";


export const CreateUnsubscribeDraft = () => (
    <Box style={{width: '50%'}}>
      <DocGroup title="More Info">
        <DocItem title="UUID">
          This is the UUID associated with the customer you are unsubscribing.
          <ul>
            <li>This is prefilled and can not be changed.</li>
          </ul>
        </DocItem>
        <DocItem title="Preserve Backend Email">
          Please select a backend user's email for preservation. 
        </DocItem>
        <DocItem title="Unsubscribe Date (Optional)">
          This is the date in which the customer will be unsubscribed. 
          <ul>
            <li>This <b>must</b> be at least 24 hours from this draft creation.</li>
          </ul>
        </DocItem>
      </DocGroup>
    </Box>
  )