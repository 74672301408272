import React, { useState } from "react";
import { renderIfPermissions } from "../api/permissionManager";
import PublishIcon from '@mui/icons-material/Publish';
import { useRecordContext } from "react-admin";

export const FileUploadComponent = ({asset}) => {
  const record = useRecordContext();
  const [fileReader] = useState(new FileReader());
  const clientKey = record.key;
  const token = localStorage.getItem("token");
  const API_URL = (window.frameElement && window.frameElement.getAttribute("data-api-url")) ||
                (process && process.env && process.env.REACT_APP_API_URL);

   const convertToFormEncoded = (data) => {
    var formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return formBody;
  }

  const handleFileUpload = (event) => {
    fileReader.onloadend = handleFileRead;
    fileReader.readAsText(event.target.files[0]);
  }

  const handleFileRead = (event,props) => {
    var data = fileReader.result;

    var jsonData;
    try {
      jsonData = JSON.parse(fileReader.result);
    }catch(e) {
      alert("Invalid json");
      return;
    }

    var requestOptions = {
      method: 'PUT',
      headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json', "eleos-admin-client-key": clientKey },
      body: data,
    };

    switch(asset) {
      case "screens":
        // api will only accept either definition or definition javascript being defined
        if("definition" in jsonData && jsonData["definition"] == null) {
          delete jsonData["definition"];
          requestOptions.body = JSON.stringify(jsonData);
        }
        if("definition_javascript" in jsonData && jsonData["definition_javascript"] == null) {
          delete jsonData["definition_javascript"];
          requestOptions.body = JSON.stringify(jsonData);
        }
        break;
      case "dashboards":
        jsonData["items"] = JSON.stringify(jsonData["items"]);
        requestOptions.body = convertToFormEncoded(jsonData);
        break;
      case "menus":
        jsonData["items"] = JSON.stringify(jsonData["items"]);
        requestOptions.body = convertToFormEncoded(jsonData);
        break;
    }

    if("code" in JSON.parse(data) === false){
      alert(`Invalid json: 'code' not present`);
      return;
    }
    
    if (asset !== "actions"){
      if(jsonData["definition"] !== undefined && typeof jsonData["definition"] !== "string"){
        alert(`Invalid json: 'definition' must be of type string`);
        return;
      }
    }

    var assetCode = JSON.parse(data)["code"];
    fetch(`${API_URL}/api/v1/${asset}/${assetCode}`, requestOptions)
      .then(response => {
        if(response.ok) {
          alert(`${asset.substring(0,asset.length-1)} (${assetCode}) uploaded/updated successfully`);
        }
        else {
          response.json().then(data => {
            var errorString = "\n";
            for (var error in data) {
              const errorObj = data[error];
              errorString = errorString + "> " + errorObj.description + "\n"
            }
            alert(`${asset.substring(0,asset.length-1)} (${assetCode}) failed to upload: ${errorString}`);
          });
        }
      });
  }

  const buttonStyle = {
     display: "inline-block",
     margin: "0 0.3em 0.3em 0",
     borderRadius: "2em",
     boxSizing: "border-box",
     textDecoration: "none",
     fontFamily: 'Roboto',
     fontWeight: "300",
     color: "#FFFFFF",
     backgroundColor: "#4eb5f1",
     textAlign: "center",
     cursor: "pointer"
  }

  const uploadBtn = <button style={buttonStyle}><label aria-label="upload-btn" htmlFor="inputfile" style={{cursor: "pointer"}}><PublishIcon /></label></button>
  return (
    <div id="FileHandler">
      <input type="file" className="inputfile" id="inputfile" onChange={(e)=> {handleFileUpload(e); e.target.value=null }} hidden/>
      <h4>Upload {uploadBtn}</h4>
    </div>
  );
}
