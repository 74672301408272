import React from "react";
import { Box } from "@mui/material";
import { DocItem, DocGroup, DocSubgroup } from "../components/DocFields";


export const BillingAccountCreateDoc = () => (
    <Box style={{width: '60%'}}>
      <DocGroup title="More Info">
        <DocItem title="Billing Account Name">
          This is what the Billing Account will be called
          <ul>
            <li>The name of the company</li>
            <li><b>CANNOT</b> be changed later</li>
          </ul>
        </DocItem>
        <DocItem title="Type">
          This is the type of billing account
          <ul>
            <li>Either 'House Account' or 'Reseller'</li>
            <li><b>CANNOT</b> be changed later</li>
          </ul>
        </DocItem>
        <DocItem title="HubSpot Company Identifier">
          This is the identifier for which company or deal this corresponds to in HubSpot.
          <ul>
            <li><b>CANNOT</b> be changed later</li>
          </ul>
        </DocItem>
      </DocGroup>
    </Box>
  )