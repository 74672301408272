import React from "react";
import { styled } from '@mui/system';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Heading = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(15),
  fontWeight: theme.typography.fontWeightRegular,
}));


export default function AppCreateDoc() {
  return(
    <div styles={{ width: '100%'}}>
      <h1>More Info</h1>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
          <Heading>Client Key</Heading>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ul>
              <li>This is the client key belonging to the live client</li>
              <li><b>CANNOT</b> be changed later</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
          <Heading>App Name</Heading>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ul>
              <li>This is what the App will be called</li>
              <li><b>CANNOT</b> be changed later</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
          <Heading>iOS Bundle Name</Heading>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ul>
              <li>Found in the HERE dev portal</li>
              <li>Reverse DNS</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
          <Heading>iOS HERE id</Heading>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ul>
              <li>From HERE dev portal project</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
          <Heading>iOS HERE code</Heading>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ul>
              <li>From HERE dev portal project</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
          <Heading>iOS HERE key</Heading>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ul>
              <li>From HERE dev portal project</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
          <Heading>Android Package Name</Heading>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ul>
              <li>From HERE dev portal project</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
          <Heading>Android HERE id</Heading>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ul>
              <li>From HERE dev portal project</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
          <Heading>Android HERE code</Heading>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ul>
              <li>From HERE dev portal project</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
          <Heading>Android HERE key</Heading>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ul>
              <li>From HERE dev portal project</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
          <Heading>Android Fingerprints</Heading>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ul>
              <li>This is the SHA-256 fingerprint for the app signing key that Google uses to sign each release.</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
          <Heading>Firebase Project id</Heading>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ul>
              <li>Found in G suite</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
          <Heading>Apple Team Identifier</Heading>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ul>
              <li>SHOULD be Eleos unless told otherwise</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
          <Heading>APNS production</Heading>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ul>
              <li>This is for Apple push notifications</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}