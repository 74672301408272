import React from "react";
import JSONPretty from 'react-json-pretty';
import DownloadLink from 'react-download-link';
import GetAppIcon from '@mui/icons-material/GetApp'
import { CircularProgress, Input, Checkbox, TextField, Button, IconButton } from '@mui/material'
import { RouteUploadComponent } from './RouteUpload';

export class RouteImportForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      apiKey : null,
      url: "https://router.hereapi.com/v8/import?lang=en-us&return=routeHandle,travelSummary,polyline&spans=duration,length,segmentId,notices&transportMode=truck&units=metric",
      routeData: {"trace":[]},
      loading: false,
      response: {}
    };
  }

  updateRouteData(data){
    this.setState({routeData: {"trace": data}});
    this.updateLoading(false)
  }

  updateLoading(load){
    this.setState({loading: load});
  }

  submitHandler = (event) => {
    event.preventDefault();
    var valid = true
    this.setState({loading: true});


    var requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json'},
      body: JSON.stringify(this.state.routeData)
    };

    if(this.state.apiKey == null || this.state.apiKey.length == 0){
      alert(`Please enter a HERE api key`);
      valid = false
    }
    if(this.state.url == null || this.state.url.length == 0){
      alert(`Please enter a URL`);
      valid = false
    }
    if(this.state.routeData["trace"].length == 0){
      alert(`Please upload a snapshot JSON file`);
      valid = false
    }

    if(valid) {
      var requestUrl = `${this.state.url}&apikey=${this.state.apiKey}`

      fetch(requestUrl, requestOptions)
        .then(response => {
          if(response.ok) {
            response.json().then(resp => {
              this.setState({response: resp});
              this.setState({loading: false});
            });
          }
          else {
            alert(`Request failed. Status: ${response.status}`);
            this.setState({loading: false});
          }
        });
    }
    else {
      this.setState({loading: false});
    }
  }

  apiKeyChange = (event) => {
    this.setState({apiKey: event.target.value});
  }

  urlChange = (event) => {
    this.setState({url: event.target.value});
  }

  render() {
    const RenderResults = (responseData, requestData, loading) => {
      if(loading){
        return(<div style={{marginLeft:"40%"}}><CircularProgress /></div>);
      }
      else {
        if(JSON.stringify(responseData).length > 10){
          return(
            <div style={{width: "600px", marginLeft: "0"}}>
              <p>Download Response Data<DownloadField data={responseData}/></p>
              <JSONPretty id="json-pretty" json={responseData}></JSONPretty>
            </div>
          );
        }
      }
    }
    const RenderSubmitButton = () => {
      if(this.state.loading == false){
        return(
          <div style={inputStyle}>
            <Button type='submit' variant="contained" color="primary">Send Request</Button>
          </div>
        );
      }
      else {
        return(<div style={{marginLeft:"40%"}}><CircularProgress /></div>);
      }
    }

    const RenderRequestDataDownload = (requestData) => {
      if(JSON.stringify(requestData).length > 20){
          return(
            <div style={{marginLeft: "5%"}}>
              <p>Download Request Body<DownloadField data={requestData}/></p>
            </div>
          );
        }
    }

    const DownloadField = (json) => {
      return <DownloadLink label={<IconButton color="primary"><GetAppIcon/></IconButton>} filename={`${this.state.username}-${this.state.loadId}.json`} exportFile={() => JSON.stringify(json.data)}/>
    }
    const LoadDownloadField = (json) => {
      return <DownloadLink label={<IconButton color="primary"><GetAppIcon/></IconButton>} filename={`${this.state.loadId}.json`} exportFile={() => getSnapshotLoadJson(json.data)}/>
    }
    const formStyle = {
      marginLeft: "300px",
    }
    const inputStyle={
      margin: "8px",
    }
    const { response, routeData, loading } = this.state;
    return (
      <div >
        <h2>Import Snapshot into HERE</h2>
        <p>This form is used to send a request to HERE which contains route data. HERE will try to import this route against their road network and return information about the import</p>
        <p>How to use...</p>
        <ol>
        <li>Input the API key, to retrieve the API key
          <ol>
            <li>Go to the <a href="https://demo.routing.ext.here.com/" target="_blank"> HERE ref client </a></li>
            <li>Click the settings/gear icon at the top right</li>
            <li>Click on App Settings</li>
            <li>Copy the API key</li>
          </ol>
        </li>
        <li>Upload snapshot JSON, which can be downloaded from the Route Snapshot Form or pulled from s3
          <ul>
            <li>This snapshot JSON will then be parsed into trace points which is what HERE consumes during the import request</li>
            <li>The trace points can be downloaded seperately once you upload the snapshot JSON, if desired</li>
          </ul>
        </li>
        </ol>
        <div style={{border: "2px solid gray", width: "80%"}}>
        <form onSubmit={this.submitHandler}>
          <div style={inputStyle}>
            <TextField
              variant="outlined"
              label="API Key"
              fullWidth
              size="small"
              onChange={this.apiKeyChange} />
          </div>
          <div style={inputStyle}>
            <TextField
              variant="outlined"
              label="URL"
              value={this.state.url}
              size="large"
              multiline
              fullWidth
              rows={5}
              onChange={this.urlChange} />
          </div>
      </form>
      <div style={inputStyle}>
        <RouteUploadComponent updateLoading={this.updateLoading.bind(this)} updateRouteData={this.updateRouteData.bind(this)}/>
      </div>
      {RenderRequestDataDownload(routeData)}
      </div>
      <form onSubmit={this.submitHandler}>
        {RenderSubmitButton()}
      </form>
      {RenderResults(response, loading)}
      </div>
    );
  }
}
