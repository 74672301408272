import React, { useEffect, useState } from 'react';
import {
  Button, ButtonGroup, CircularProgress, FormControl, FormControlLabel, FormGroup, Input,
  InputLabel, MenuItem, Paper, Radio, Select, Stack, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Typography
} from '@mui/material';
import { styled } from '@mui/system';
import { useLogout, usePermissions } from 'react-admin';
import { ENVIRONMENT } from '../config';
import FailedIcon from '@mui/icons-material/Clear';
import SuccessIcon from '@mui/icons-material/Check';
import PendingIcon from '@mui/icons-material/HorizontalRule';
import TriggeredIcon from '@mui/icons-material/HourglassBottomTwoTone';
import { renderIfPermissions } from '../api/permissionManager';
import ErrorIcon from '@mui/icons-material/Error';
SuccessIcon.displayName = 'SuccessIcon'
FailedIcon.displayName = 'FailedIcon'
PendingIcon.displayName = 'PendingIcon'
ErrorIcon.displayName = 'ErrorIcon'

const API_URL = (window.frameElement && window.frameElement.getAttribute("data-api-url")) ||
  (process && process.env && process.env.REACT_APP_API_URL);

let LAMBDA_URL = (process && process.env && process.env.REACT_APP_LAMBDA_URL) ? process.env.REACT_APP_LAMBDA_URL : 'http://localhost:3001/BogusLambda'
if (process && process.env && process.env.REACT_APP_TESTING === 'true') LAMBDA_URL = 'http://localhost:3001/BogusLambda'

const StyledTriggeredIcon = styled(TriggeredIcon)(({ theme }) => ({
  animation: `$rotate 2s linear infinite`,
  color: '#FFDE00', // Use hex color codes with a leading '#'
  '@keyframes rotate': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' }
  }
}));

const FadeIcon = styled('div')({
  animation: 'fade 500ms linear',
  '@keyframes fade': {
    from: { opacity: '0%' },
    to: { opacity: '100%' }
  }
});

// This is a helper function to fetch data
const fetchData = async (url, method, body) => {
  try {
    const response = await fetch(url, {
      method,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      body: body ? JSON.stringify(body) : null
    });
    if (response.status === 403) return 403;
    if (method === 'GET' && response.status === 404) return 'never-triggered';
    if (!response.ok) {
      throw new Error(`Failed to fetch data from ${url}. Received ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error(`Error fetching data from ${url}:`, error);
    throw error;
  }
}

// This get's a build status for a specific pipeline_uuid
const getBuildStatus = async (uuid) => {
  try {
    const data = await fetchData(`${API_URL}/internal/apps/status/${uuid}`, 'GET');
    if (data === 403) logout();
    return data.errors ? JSON.stringify(data.errors) : data.status;
  } catch (error) {
    console.error(`Error fetching build status for ${uuid}:`, error);
  }
};

// This gets the trigger status
const getTriggerStatus = async (client_key, platform_type, platform_tag) => {
  const circleUrl = platform_type === 'android' ? 'mobile-reference-android' : 'mobile-reference-iphone'
  try {
    const statusData = await fetchData(`${API_URL}/internal/apps/${client_key}/statuses`, 'GET');
    if (statusData === 403) return 403;
    if (statusData.length === 0) return 'not-triggered';

    const matchingBuild = statusData.find(status => status.tag === platform_tag && status?.circle_url.includes(circleUrl));
    if (matchingBuild) return await getBuildStatus(matchingBuild.pipeline_uuid)
    const statuses = statusData.filter(status => status.tag === platform_tag);
    if (statuses.length > 0) {
      statuses.sort((a, b) => new Date(b.triggered_at) - new Date(a.triggered_at));
      if (statuses[0].circle_url === "No workflows on this build") return 'Build not found';
    }
    return 'not-triggered';
  } catch (error) {
    console.error(`Error fetching trigger status data for ${client_key}:`, error);
  }
}

//This handles the logic for exporting to a csv file.
const exportToCsv = (data, fileName) => {
  const csvContent = "data:text/csv;charset=utf-8," + data.map(row => row.join(",")).join("\n");
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const BulkTriggerForm = () => {
  const {permissions} = usePermissions();
  const [releases, setReleases] = useState([]);
  const [releaseChoice, setReleaseChoice] = useState('');
  const [androidTag, setAndroidTag] = useState('');
  const [iosTag, setIosTag] = useState('');
  const [triggered, setTriggered] = useState(false);
  const [statusClick, setStatusClick] = useState(false);
  const [androidClientKeysFile, setAndroidClientKeysFile] = useState(null);
  const [androidInputFormat, setAndroidInputFormat] = useState('list');
  const [iosInputFormat, setIosInputFormat] = useState('list');
  const [iosClientKeysFile, setIosClientKeysFile] = useState(null);
  const [androidClientKeys, setAndroidClientKeys] = useState([]);
  const [iosClientKeys, setIosClientKeys] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [remainingTime, setRemainingTime] = useState(120);
  const logout = useLogout();

  //When first mounting, we want to have all available releases.
  useEffect(() => {
    refreshReleases();
  }, []);

  //This handles the logic for changing the release
  const changeRelease = async () => {
    let matchingRelease = releases.find(release => release.release_name === releaseChoice);
    setAndroidTag(matchingRelease.android_tag)
    setIosTag(matchingRelease.ios_tag);
    setAutoRefresh(false);
    setStatusClick(false);
    await getLambdaStatus(matchingRelease.android_tag, matchingRelease.ios_tag);
  }

  //When a release choice is made, we call the changeRelease function.
  useEffect(() => {
    if (releaseChoice) changeRelease()
  }, [releaseChoice]);

  // Update table rows based on status click and client keys
  useEffect(() => {
    if (releaseChoice && statusClick) renderTableRows();
    else setTableRows([]);
  }, [statusClick, androidClientKeys, iosClientKeys]);

  // This will auto refresh the statuses in the table rows for convenience
  useEffect(() => {
    let refreshInterval;
    if (releaseChoice && (statusClick || triggered)) {
      // Start countdown from 120 seconds
      setRemainingTime(120);
      // Interval to update countdown
      refreshInterval = setInterval(() => {
        setRemainingTime(prevTime => {
          if (prevTime === 0) {
            // If countdown reaches 0, call renderTableRows and reset the countdown
            renderTableRows();
            return 120;
          } else {
            return prevTime - 1;
          }
        });
      }, 1000);
    }

    return () => {
      clearInterval(refreshInterval);
    };
  }, [statusClick, triggered, releaseChoice]);

  /* This sends a GET request to the lambda function 
   * and checks to see if it's still running based on the chosen release.
  */
  const getLambdaStatus = async (android_tag, ios_tag) => {
    if (releaseChoice) {
      try {
        const response = await fetchData(`${LAMBDA_URL}?composite_tag=${android_tag}/${ios_tag}`, 'GET');
        if (response.lambda_status === "Stopped" || response.lambda_status === 'never-triggered') setTriggered(false)
        else if (response.lambda_status === "Triggered") setTriggered(true)
      } catch (error) {
        console.error('Error calling lambda GET function: ', error)
      }
    }
  }

  //This sends a POST request to the lambda function and begins the triggering process.
  const postTriggerLambda = async (android_keys, ios_keys, android_tag, ios_tag) => {
    const body = {
      "environment": ENVIRONMENT,
      "android_keys": android_keys,
      "ios_keys": ios_keys,
      "android_tag": android_tag,
      "ios_tag": ios_tag,
      "auth_token": localStorage.getItem('token')
    };
    try {
      await fetchData(LAMBDA_URL, 'POST', body);
      await getLambdaStatus(android_tag, ios_tag)
    } catch (error) {
      console.error('Error trying to POST to lambda function: ', error);
    }
  }

  //This gets all available releases
  const refreshReleases = async () => {
    let new_releases = []
    const response = await fetchData(`${API_URL}/internal/app_releases`, 'GET');
    if (response === 403) logout();
    else response.map((release) => (new_releases.push(release)))
    setReleases(new_releases);
  };

  //This handles when a user clicks the clear button.
  const handleClear = () => {
    setStatusClick(false);
    setReleaseChoice('');
    setAndroidTag('');
    setIosTag('');
    setAndroidClientKeys([]);
    setIosClientKeys([]);
    setAndroidClientKeysFile(null);
    setIosClientKeysFile(null);
    document.getElementById('android-file-input') && (document.getElementById('android-file-input').value = '');
    document.getElementById('ios-file-input') && (document.getElementById('ios-file-input').value = '');
    setAutoRefresh(false);
  }

  // This handles when a user clicks the status button.  
  const handleStatusClick = () => {
    if (androidClientKeys.length > 0 || iosClientKeys.length > 0) {
      if (releaseChoice) {
        setStatusClick(true);
        setAutoRefresh(true);
      }
      else alert('Please select a release first.')
    }
    else alert('Please provide a list of keys.')
  }

  //This handles changing between user input options of file or list
  const handleKeyInputOptionChange = (option, platform) => {
    if (option === 'list') platform === 'android' ? setAndroidInputFormat('list') : setIosInputFormat('list');
    else platform === 'android' ? setAndroidInputFormat('file') : setIosInputFormat('file');
  };

  //This handles when a user chooses to upload a file of client keys.
  const handleFileChange = (e, platform) => {
    setStatusClick(false);
    const file = e.target.files[0];
    if (file && file.type !== 'text/csv') {
      alert('Please select a valid CSV file.');
      e.target.value = '';
      return;
    }

    platform === 'android' ? setAndroidClientKeysFile(file) : setIosClientKeysFile(file);
    const reader = new FileReader();
    reader.onload = (event) => {
      const content = event.target.result;
      const keysArray = content.split('\n').map(line => line.trim());
      platform === 'android' ? setAndroidClientKeys(keysArray) : setIosClientKeys(keysArray)
    };
    reader.readAsText(file);
  }

  //This handles when a user chooses to manually enter client keys.
  const handleListChange = (e, platform) => {
    setStatusClick(false);
    const keys = e.target.value.split('\n').map(line => line.trim())
    if (keys.length === 1 && keys[0] === "") platform === 'android' ? setAndroidClientKeys([]) : setIosClientKeys([])
    else platform === 'android' ? setAndroidClientKeys(keys) : setIosClientKeys(keys)
  };

  //This handles when a user clicks the bulk build button.
  const handleBulkBuildClick = async () => {
    if (!releaseChoice) {
      alert('Please select a release first.')
      return;
    }
    if (!androidClientKeys.length > 0 && !iosClientKeys.length > 0) {
      alert('Please provide a list of keys.')
      return;
    }
    setTriggered(true);
    setAutoRefresh(true);
    renderTableRows();
    const android_keys = !androidClientKeys ? [] : androidClientKeys
    const ios_keys = !iosClientKeys ? [] : iosClientKeys
    postTriggerLambda(android_keys, ios_keys, androidTag, iosTag)
  };

  // This is for decriptions in CSV file
  const getStatusString = (status) => {
    const displayName = status?.displayName;
    if(!displayName) return '';
    if (displayName === 'SuccessIcon') return 'successful';
    else if (displayName === 'FailedIcon') return 'failed';
    else if (displayName === 'ErrorIcon') return 'Error: Check Build'
    else return 'not triggered';
  };

  //This get the data to export to a csv file based on a specific status.
  const exportCsv = async (status) => {
    const filteredRows = tableRows.filter((row) => {
      const androidStatus = row.props.children[1].props.children?.type;
      const iosStatus = row.props.children[2].props.children?.type;
      if (status === 'success' && (androidStatus?.displayName === 'SuccessIcon' || iosStatus?.displayName === 'SuccessIcon')) return true;
      else if (status === 'failed' && (androidStatus?.displayName === 'FailedIcon' || iosStatus?.displayName === 'FailedIcon')) return true;
      else if (status === 'not-triggered' && androidStatus?.displayName !== 'SuccessIcon' && iosStatus?.displayName !== 'SuccessIcon' && androidStatus?.displayName !== 'FailedIcon' && iosStatus?.displayName !== 'FailedIcon') return true;
      return false;
    });

    const data = [['Client Key', 'Android Status', 'iOS Status']];
    filteredRows.forEach((row) => {
      const rowData = [
        row.props.children[0].props.children,
        getStatusString(row.props.children[1].props.children?.type),
        getStatusString(row.props.children[2].props.children?.type),
      ];
      data.push(rowData);
    });
    const fileName = `${status}_clients.csv`;
    exportToCsv(data, fileName);
  };

  /*
   * This renders the first half of the form.
   * Selector for releases (will show if lambda is running or not for specified release), 
   * Android/Ios clients input (manual list, or upload file)
   * Bulk build button, status button, and clear button
  */
  const renderBuildSelect = () => {
    let options = releases.map((opts) => opts.release_name);
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div>
          <Stack style={{ marginTop: '2em' }} direction="row" alignItems="center" gap={1}>
            <FormControlLabel
              control={
                <FormControl sx={{ m: 1, minWidth: 200, maxWidth: 200 }}>
                  <InputLabel id="release-input">Bulk Release</InputLabel>
                  <Select className='release-input' id="release-input" value={releaseChoice} label="Bulk Release" onChange={(e) => { setReleaseChoice(e.target.value) }}>
                    {options && options.map((option, i) => (
                      <MenuItem key={i} value={option}>{option}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              }
            />
            {releaseChoice && (
              triggered ? (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <label htmlFor="trigger-running"><b>Trigger Running</b></label>
                  <CircularProgress style={{ color: '#2af70a' }} size={25} />
                </div>
              ) : (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <label htmlFor="trigger-stopped"><b>Not Running</b></label>
                  <FailedIcon style={{ color: 'red' }} />
                </div>
              )
            )}
          </Stack>
        </div>

        <div style={{ marginTop: '1em' }}>
          <FormControl style={{ marginRight: '2em' }}>
            <div>
              <Typography style={{ fontSize: 'smaller' }}><b>Android Clients</b></Typography>
              <Radio
                id="android-list"
                name="android-option"
                checked={androidInputFormat === 'list'}
                onChange={() => handleKeyInputOptionChange('list', 'android')}
              />
              <label htmlFor="android-list">List</label>
              <Radio
                id="android-file"
                name="android-option"
                checked={androidInputFormat === 'file'}
                onChange={() => handleKeyInputOptionChange('file', 'android')}
              />
              <label htmlFor="android-file">File</label>
            </div>
            {androidInputFormat === 'list' ? (
              <textarea
                rows={10}
                cols={25}
                placeholder={"Enter Android client keys \n(one per line)"}
                value={androidClientKeys.join('\n')}
                onChange={(e) => handleListChange(e, 'android')}
              />
            ) : (
              <Input id='android-file-input' type='file' onChange={(event) => { handleFileChange(event, 'android') }} style={{ margin: '1em 0' }} />
            )}
          </FormControl>

          <FormControl>
            <div>
              <Typography style={{ fontSize: 'smaller' }}><b>iOS Clients</b></Typography>
              <Radio
                id="ios-list"
                name="ios-option"
                checked={iosInputFormat === 'list'}
                onChange={() => handleKeyInputOptionChange('list', 'ios')}
              />
              <label htmlFor="ios-list">List</label>
              <Radio
                id="ios-file"
                name="ios-option"
                checked={iosInputFormat === 'file'}
                onChange={() => handleKeyInputOptionChange('file', 'ios')}
              />
              <label htmlFor="ios-file">File</label>
            </div>
            {iosInputFormat === 'list' ? (
              <textarea
                rows={10}
                cols={25}
                placeholder={"Enter iOS client keys \n(one per line)"}
                value={iosClientKeys.join('\n')}
                onChange={(e) => handleListChange(e, 'ios')}
              />
            ) : (
              <Input id='ios-file-input' type='file' onChange={(event) => { handleFileChange(event, 'ios') }} style={{ margin: '1em 0' }} />
            )}
          </FormControl>
        </div>
        <ButtonGroup style={{ marginTop: '1em' }}>
          {/*
            * When the 'Bulk Build' button is clicked or the lambda function is running, it will be disabled.
            * This prevents the user from sending a new request to the lambda for an already running process.
           */}
          {renderIfPermissions(permissions, "apps", "write", 
            <Button
            style={{ marginRight: '1em' }}
            variant='contained'
            disabled={triggered}
            onClick={handleBulkBuildClick}>Bulk Build</Button>)}
          {/*
            * When the status button is clicked, it will be disabled.
            * This is to prevent the user from spamming GET requests to the server.
            * However, we still want to regularly get the status. So this enables auto refresh. 
           */}
          <Button
            style={{ marginRight: '1em' }}
            variant='contained'
            disabled={statusClick}
            onClick={handleStatusClick}>Status</Button>
          <Button
            variant='contained'
            color='error'
            onClick={handleClear}>Clear</Button>
        </ButtonGroup>
        {autoRefresh && (
          <Typography
            style={{ 
              padding: '0.4em', 
              borderRadius: '1em', 
              marginTop: '0.5em', 
              backgroundColor: '#b3fc9d', 
              color: '#27471d' 
            }}>Auto refresh is enabled
              <p style={{fontSize:'small',marginTop:'-0.1em', marginBottom:'-0.1em'}}>
                Refreshing in <b>{remainingTime}</b> seconds
              </p>
          </Typography>
        )}
      </div>
    );
  };

  //This takes the status of a client key's build and display the appropriate component.
  const renderStatusComponent = (status) => {
    if (status === 'success') return <SuccessIcon style={{ marginLeft: '1em', color: 'green' }} />;
    else if (status === 'failed') return <FailedIcon style={{ marginLeft: '1em', color: 'red' }} />;
    else if (status === 'running' || status === null) return <StyledTriggeredIcon style={{ marginLeft: '1em' }} />;
    else if (status === 'not-triggered') return <PendingIcon style={{ marginLeft: '1em' }} />
    else if (status === 'Build not found') return <ErrorIcon style={{ marginLeft: '1em', color:'CC3300'}} />;
    else return status;
  };

  //This gets the status of a client key's build.
  const getStatus = async (client) => {
    const isAndroidClient = androidClientKeys.includes(client);
    const isIosClient = iosClientKeys.includes(client);
    if (!androidClientKeys.includes(client) && !iosClientKeys.includes(client)) return null;
    try {
      await getLambdaStatus(androidTag, iosTag);
      let androidStatus, iosStatus;

      if (isAndroidClient) {
        androidStatus = await getTriggerStatus(client, 'android', androidTag);
        if (androidStatus === 403) logout();
      }
      if (isIosClient) {
        iosStatus = await getTriggerStatus(client, 'ios', iosTag);
        if (iosStatus === 403) logout();
      }
      return { androidStatus, iosStatus };
    } catch (error) {
      console.error('Error rendering status:', error);
      return null;
    }
  };

  /* 
   * This gets the status of each client key's build,
   * then displays the appropriate component in the corresponding table row.
  */
  const renderTableRows = async () => {
    try {
      setLoading(true);
      const rows = await Promise.all(
        [...new Set([...androidClientKeys, ...iosClientKeys])].map(async (client, index) => {
          const androidId = `android-${index}`
          const iosId = `ios-${index}`
          const { androidStatus, iosStatus } = await getStatus(client);
          if (androidStatus || iosStatus) {
            return (
              <TableRow key={`combined-${client}`}>
                <FadeIcon><TableCell id={index}>{client}</TableCell></FadeIcon>
                {androidClientKeys.includes(client) ? <FadeIcon><TableCell id={androidId}>{renderStatusComponent(androidStatus)}</TableCell></FadeIcon> : <TableCell></TableCell>}
                {iosClientKeys.includes(client) ? <FadeIcon><TableCell id={iosId}>{renderStatusComponent(iosStatus)}</TableCell></FadeIcon> : <TableCell></TableCell>}
              </TableRow>
            );
          }
          return null;
        })
      );
      setTableRows(rows.filter(Boolean));
    } finally {
      setLoading(false);
    }
  };

  /*
   * This checks to see what statuses there are.
   * Then will return the appropriate export button.
   * Example: 
   *    If there is 1 successful and 1 failed,
   *    Only the Success Export and Failed Export buttons will display.
  */

  const renderExportButtons = () => {
    const hasSuccessfulClients = tableRows.some(row => {
      const androidStatus = row.props.children[1].props.children?.type;
      const iosStatus = row.props.children[2].props.children?.type;
      return androidStatus?.displayName === 'SuccessIcon' || iosStatus?.displayName === 'SuccessIcon'
    });

    const hasFailedClients = tableRows.some(row => {
      const androidStatus = row.props.children[1].props.children?.type;
      const iosStatus = row.props.children[2].props.children?.type;
      return androidStatus?.displayName === 'FailedIcon' || iosStatus?.displayName === 'FailedIcon'
    });

    const hasNotTriggeredClients = tableRows.some(row => {
      const androidStatus = row.props.children[1].props.children?.type;
      const iosStatus = row.props.children[2].props.children?.type;
      return (androidStatus?.displayName !== 'SuccessIcon' && androidStatus?.displayName !== 'FailedIcon' && iosStatus?.displayName !== 'SuccessIcon' && iosStatus?.displayName !== 'FailedIcon')
    });

    return (
      <ButtonGroup style={{ marginTop: '1em', marginBottom: '1em' }}>
        {hasSuccessfulClients &&
          <Button
            style={{ marginRight: '1em' }}
            variant='contained'
            onClick={() => exportCsv('success')}
          >Export Successful
          </Button>
        }
        {hasFailedClients &&
          <Button
            style={{ marginRight: '1em' }}
            variant='contained'
            onClick={() => exportCsv('failed')}
          >Export Failed
          </Button>
        }
        {hasNotTriggeredClients &&
          <Button
            variant='contained'
            onClick={() => exportCsv('not-triggered')}
          >Export Not Triggered
          </Button>
        }
      </ButtonGroup>
    );
  };

  return (
    <div style={{ marginTop: '4em', marginLeft: '2em', textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <h3>Trigger Bulk Builds</h3>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <FormGroup>{renderBuildSelect()}</FormGroup>
      </div>
      {(androidClientKeys.length > 0 || iosClientKeys.length > 0) && (
        <>
          <Stack
            style={{
              marginTop: '2em',
              paddingTop: '0.5em',
              paddingBottom: '0.5em',
              paddingLeft: '1em',
              paddingRight: '1em',
              borderRadius: '1em'
            }}
            direction="row"
            alignItems="center"
            gap={1}
          >
            <FadeIcon>
              <Typography><b>Legend:</b></Typography>
              <PendingIcon />
              <Typography style={{ marginLeft: '-0.5em' }}>Not Triggered</Typography>
              <StyledTriggeredIcon />
              <Typography style={{ marginLeft: '-0.5em' }}>Triggered</Typography>
              <FailedIcon style={{ color: 'red' }} /><Typography style={{ marginLeft: '-0.5em' }}>Failed</Typography>
              <SuccessIcon style={{ color: 'green' }} /><Typography style={{ marginLeft: '-0.5em' }}>Success</Typography>
              <ErrorIcon style={{ color: 'CC3300' }} /><Typography style={{ marginLeft: '-0.5em' }}>Build Not Found</Typography>
            </FadeIcon>
          </Stack>
          <FadeIcon>
            <TableContainer style={{ marginBottom: '3em' }} component={Paper}>
              <Table size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell><b>Client Key</b></TableCell>
                    {androidClientKeys.length > 0 ? (<TableCell><b>Android Status</b></TableCell>) : (<TableCell></TableCell>)}
                    {iosClientKeys.length > 0 ? (<TableCell><b>iOS Status</b></TableCell>) : <TableCell></TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <FadeIcon>
                      <TableRow>
                        <TableCell colSpan={3} align="center">
                          <Typography>Refreshing</Typography>
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    </FadeIcon>
                  ) : (tableRows)
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </FadeIcon>
          
          {(statusClick || triggered) && renderExportButtons()}
        </>
      )}
    </div>
  );
};