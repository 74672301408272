import React from "react";
import { usePermissions } from "react-admin";
import { CircularProgress, Paper, Table, TableBody, TableRow, TableCell, List, ListItem, ListItemText, Divider, Input, Checkbox, TextField, Button, IconButton } from '@mui/material'
import { renderIfPermissions } from "../api/permissionManager";

const PermissionedCheckbox = ({ label, checked, onChange }) => {
  const { permissions } = usePermissions();

  return renderIfPermissions(permissions, 'clients', "write", (
    <>
    <TableCell component="th" scope="row">{label}</TableCell>
    <TableCell align="left">
      <Checkbox
        checked={checked}
        onChange={onChange}
      />
    </TableCell>
    </>
  ));
};

const ThresholdToggle = ({ userJson, updateSpeedThreshold }) => {
  const { permissions } = usePermissions();

  if (userJson) {
    if (userJson.disable_speed_threshold === true) {
      return renderIfPermissions(permissions, "clients", "write",
        <Button
          id="toggle-button"
          variant="contained"
          color="primary"
          onClick={() => updateSpeedThreshold(false)}
        >
          Enable Speed Threshold
        </Button>
      );
    } else {
      return renderIfPermissions(permissions, "clients", "write",
        <Button
          id="toggle-button"
          variant="contained"
          color="primary"
          onClick={() => updateSpeedThreshold(true)}
        >
          Disable Speed Threshold
        </Button>
      );
    }
  }

  return null;
};

export class UserSearchForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      clientKey: this.props.record.key,
      token: localStorage.getItem("token"),
      apiUrl: (window.frameElement && window.frameElement.getAttribute("data-api-url")) ||
        (process && process.env && process.env.REACT_APP_API_URL),
      userJson: null,
      loading: false
    };
  }

  submitHandler = (event) => {
    this.setState({ loading: true });

    event.preventDefault();
    var valid = true

    var requestOptions = {
      method: 'GET',
      headers: { 'Authorization': `Bearer ${this.state.token}`, 'Content-Type': 'application/json' }
    };

    if (valid) {
      var requestUrl = `${this.state.apiUrl}/internal/platform_users/${this.state.clientKey}/${this.state.username}`

      fetch(requestUrl, requestOptions)
        .then(response => {
          if (response.ok) {
            response.json().then(resp => {
              this.setState({ userJson: resp });
              this.setState({ loading: false });
            });
          }
          else if (response.status == "403") {
            window.location.reload(false);
          }
          else {
            this.setState({ userJson: null });
            this.setState({ loading: false });
          }
        });
    }
  }

  updateSpeedThreshold = (value) => {
    var requestUrl = `${this.state.apiUrl}/internal/platform_users/${this.state.clientKey}/${this.state.username}`
    var body = { "disable_speed_threshold": value }
    var requestOptions = {
      method: 'PATCH',
      headers: { 'Authorization': `Bearer ${this.state.token}`, 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    };

    fetch(requestUrl, requestOptions)
      .then(response => {
        if (response.ok) {
          response.json().then(resp => {
            this.setState({ userJson: resp });
            this.setState({ loading: false });
          });
        }
        else if (response.status == "403") {
          window.location.reload(false);
        }
        else {
          this.setState({ loading: false });
        }
      });
  }

  userShow = () => {
    const style = {
      marginLeft: "25%",
      width: "40%"
    };

    const listStyle = {
      width: "30%",
      maxWidth: 40
    }

    if (this.state.loading == true) {
      return (<div style={{ marginLeft: "40%" }}><CircularProgress /></div>)
    }

    else if (this.state.userJson == null) {
      return (<div style={{ marginLeft: "35%" }}><h2>User Not Found</h2></div>)
    }
    else {
      var user = this.state.userJson;
      return (
        <div style={style}>
          <Paper >
            <Table aria-label="simple table">
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Username
                  </TableCell>
                  <TableCell align="left">{user.username}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Full Name
                  </TableCell>
                  <TableCell align="left">{user.full_name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Email
                  </TableCell>
                  <TableCell align="left">{user.email}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Active
                  </TableCell>
                  <TableCell align="left"><Checkbox checked={user.active} /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    App Version
                  </TableCell>
                  <TableCell align="left">{user.last_drive_axle_version}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Last Device Name
                  </TableCell>
                  <TableCell align="left">{user.last_device_name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Last Device Identifier
                  </TableCell>
                  <TableCell align="left">{user.last_device_identifier}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Last Device Type
                  </TableCell>
                  <TableCell align="left">{user.last_device_type}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Created At
                  </TableCell>
                  <TableCell align="left">{user.created_at}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Last Opened App
                  </TableCell>
                  <TableCell align="left">{user.current_sign_in_at}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Updated At
                  </TableCell>
                  <TableCell align="left">{user.updated_at}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Last Sign In
                  </TableCell>
                  <TableCell align="left">{user.last_sign_in_at}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Last Refresh
                  </TableCell>
                  <TableCell align="left">{user.last_refreshed_at}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Sign In Count
                  </TableCell>
                  <TableCell align="left">{user.sign_in_count}</TableCell>
                </TableRow>
                <TableRow>
                  <PermissionedCheckbox label={"Disallow Metered Map Downloads"} checked={user.disallow_metered_map_downloads} onChange={() => { }} />
                </TableRow>
                <TableRow>
                  <PermissionedCheckbox label={"Soft Here Routing Restrictions"} checked={user.soft_here_routing_restrictions} onChange={() => { }} />
                </TableRow>
                <TableRow>
                  <PermissionedCheckbox label={"Force Online Hours Of Service"} checked={user.force_online_hours_of_service} onChange={() => { }} />
                </TableRow>
                <TableRow>
                  <PermissionedCheckbox label={"Disable Speed Threshold"} checked={user.disable_speed_threshold} onChange={() => { }} />
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
          <br></br>
          <ThresholdToggle
            userJson={this.state.userJson}
            updateSpeedThreshold={this.updateSpeedThreshold}
          />
        </div>
      );
    }
  }

  usernameChange = (event) => {
    this.setState({ username: event.target.value });
  }

  render() {
    const formStyle = {
      marginLeft: "35%",
    }
    const inputStyle = {
      margin: "8px",
    }

    return (
      <div>
        <div style={formStyle}>
          <h2>Search For User</h2>
          <form onSubmit={this.submitHandler}>
            <div style={inputStyle}>
              <TextField
                variant="outlined"
                label="Username"
                size="small"
                onChange={this.usernameChange} />
            </div>
            <div style={inputStyle}>
              <Button type='submit' variant="contained" color="primary">Search</Button>
            </div>
          </form>
          <br></br>
        </div>
        {this.userShow(this.props)}
      </div>
    );
  }
}
