import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Button, Container, FormControl, FormHelperText, Grid, IconButton, Input, Paper, Table, TableBody, TableCell, TableRow } from "@mui/material";
import Typography from '@mui/material/Typography';
import emptyRedMarker from '../resources/img/marker-empty-red.svg';
import emptyGreenMarker from '../resources/img/marker-empty-green.svg';
import emptyBlueMarker from '../resources/img/marker-empty-blue.svg';
import filledGreenMarker from '../resources/img/marker-filled-green.svg';
import filledBlueMarker from '../resources/img/marker-filled-blue.svg';
import filledRedMarker from '../resources/img/marker-filled-red.svg';
import alertMarker from '../resources/img/alert.svg';
import TuneIcon from '@mui/icons-material/Tune';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GetAppIcon from '@mui/icons-material/GetApp'

const HERE_API_KEY = (window.frameElement && window.frameElement.getAttribute("here-map-api-key"))
    ?? (process && process.env && process.env.REACT_APP_HERE_MAP_API_KEY)
    ?? "WhoAreYou?ThisIsABogusKey";

export class TripPlannerMap extends React.Component {
    constructor(props) {
        super(props);
        this.mapRef = React.createRef();
        this.fileInputRef = React.createRef();
        this.state = {
            jsonData: props.snapShotData,
            routingOpts: [],
            routerEngine: [],
            mapIndicators: false,
            mapInitialized: false,
            routeBounds: null,
            markers: [],
            lastClickedMarker: null,
            uploadedFile: false,
            fileError: false
        };
    }

    //Helper functions to get all routing options
    getRoutingOpts = (json) => {
        let opts = [];
        let engine = [];
        if (json.trip_plan && json.trip_plan.request && json.trip_plan.request.stops) {
            for (const stop of json.trip_plan.request.stops) {
                if (stop.routing_options) {
                    if (Object.keys(stop.routing_options).length > 0) {
                        // If routing_options is not an empty object, push it to the opts array
                        opts.push(stop.routing_options);
                    }
                    else {
                        // If routing_options is an empty object, assign null to each property
                        const route_opts = {
                            truck: {
                                type: "tractor_truck",
                                height: null,
                                width: null,
                                length: null,
                                limited_weight: null,
                                trailer_count: null,
                            }
                        };
                        opts.push(route_opts);
                    }
                }
                if (stop.router && stop.router.engine) {
                    engine.push(stop.router.engine)
                }
            }
        }
        this.setState({ routerEngine: engine })
        return opts;
    };

    componentDidMount() {
        try {
            // Initialize the map on component mount
            this.initializeMap();
            // Check if snapshot data is available from props
            if (this.props.snapShotData) {
                // Retrieve routing options based on snapshot data
                const opts = this.getRoutingOpts(this.props.snapShotData)
                this.setState({ routingOpts: opts })
                // Draw the route on the map using the snapshot data
                this.drawRouteOnMap(this.props.snapShotData);
            }
        } catch (error) {
            console.error('Error in componentDidMount: ', error);
        }

    }

    initializeMap = () => {
        const { mapIndicators } = this.state;
        if (!this.mapRef.current) return;
        const platform = new H.service.Platform({
            apiKey: HERE_API_KEY
        });
        const defaultLayers = platform.createDefaultLayers();
        let map;
        map = new H.Map(this.mapRef.current, defaultLayers.vector.normal.truck, {
            center: { lat: 37.0902, lng: -95.7129 },
            zoom: 4.5,
            pixelRatio: window.devicePixelRatio || 1
        });

        // add a resize listener to make sure that the map occupies the whole container
        window.addEventListener('resize', () => map.getViewPort().resize());

        // Make the map interactive
        var behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
        // create default UI with layers provided by the platform
        this.ui = H.ui.UI.createDefault(map, defaultLayers);
        this.map = map;
        this.setState({ mapInitialized: true });
    };

    handleFileUpload = (event) => {
        try {
            const file = event.target.files[0];
            this.setState({ uploadedFile: true })
            if (!file) {
                // User canceled the file selection
                return;
            }
            const reader = new FileReader();

            reader.onload = (e) => {
                try {
                    const jsonData = JSON.parse(e.target.result);
                    if ((jsonData && jsonData.trip_plan && Array.isArray(jsonData.trip_plan.stops))) {
                        const opts = this.getRoutingOpts(jsonData)
                        this.setState({
                            jsonData,
                            routingOpts: opts,
                            fileError: false
                        });
                        this.drawRouteOnMap(jsonData);
                    } else {
                        alert("Invalid JSON data format");
                        // Remove map objects
                        this.map.getObjects().forEach((obj) => {
                            this.map.removeObject(obj);
                        });
                        // Reset map zoom
                        if (this.map) {
                            this.map.setZoom(4.5);
                            this.map.setCenter({ lat: 37.0902, lng: -95.7129 });
                        }
                        this.setState({
                            fileError: true,
                            jsonData: null
                        });
                    }
                } catch (error) {
                    this.setState({ fileError: true })
                    console.error('Error parsing JSON data: ', error)
                }
            };
            reader.readAsText(file);
        } catch (error) {
            console.error('Error handling file upload:', error);
        }
    };

    drawRouteOnMap = (jsonData) => {
        try {
            // Remove any previous map objects to avoid multiple routes being displayed.
            this.map.getObjects().forEach((obj) => {
                this.map.removeObject(obj);
            });
            // Create a LineString to represent the route geometry.
            const lineString = new H.geo.LineString();
            if (jsonData.trip_plan.stops) {
                // Iterate through stops to extract geometry segments.
                jsonData.trip_plan.stops.forEach((stop, index) => {
                    if (stop.geometry_segments) {
                        stop.geometry_segments.forEach((segment, s) => {
                            if (segment.geometry) {
                                // Iterate through coordinates in each segment.
                                segment.geometry.forEach((coord, c) => {
                                    lineString.pushLatLngAlt(coord.latitude, coord.longitude);
                                });
                            }
                        });
                    }
                });
                // Create a polyline with the route geometry and add it to the map.
                const polyline = new H.map.Polyline(lineString, { style: { strokeColor: 'blue', lineWidth: 5, }, });
                this.map.addObject(polyline);
                // Set the view to the bounds of the polyline.
                const routeBounds = polyline.getBoundingBox();
                this.setState({ routeBounds: routeBounds })
                this.map.getViewModel().setLookAtData({ bounds: routeBounds }, { opt_animate: true });

                // Create markers array for stops and initialize markers' state.
                const markers = jsonData.trip_plan.request.stops.map((stop) => ({
                    latitude: stop.location.latitude,
                    longitude: stop.location.longitude,
                    isClicked: false,
                    marker: null
                }));
                this.setState({ markers })

                // Add start, end, and intermediate markers based on stop locations.
                jsonData.trip_plan.request.stops.forEach((stop, index) => {
                    const { latitude, longitude } = stop.location || {};
                    if (index === 0 && latitude !== undefined && longitude !== undefined) {
                        //First stop = Green
                        const greenMarkerLocation = { lat: latitude, lng: longitude }
                        const greenIcon = new H.map.Icon(emptyGreenMarker, { size: { w: 40, h: 45 } });
                        const greenMarker = new H.map.Marker(greenMarkerLocation, { icon: greenIcon });
                        this.map.addObject(greenMarker);
                        markers[index].marker = greenMarker;
                    } else if (index === jsonData.trip_plan.request.stops.length - 1 && latitude !== undefined && longitude !== undefined) {
                        //Last stop = Red
                        const redMarkerLocation = { lat: latitude, lng: longitude };
                        const redIcon = new H.map.Icon(emptyRedMarker, { size: { w: 40, h: 45 } });
                        const redMarker = new H.map.Marker(redMarkerLocation, { icon: redIcon });
                        this.map.addObject(redMarker);
                        markers[index].marker = redMarker;
                    } else if (latitude !== undefined && longitude !== undefined) {
                        //Intermediate stop = Blue
                        const blueMarkerLocation = { lat: latitude, lng: longitude };
                        const blueIcon = new H.map.Icon(emptyBlueMarker, { size: { w: 40, h: 45 } });
                        const blueMarker = new H.map.Marker(blueMarkerLocation, { icon: blueIcon });
                        this.map.addObject(blueMarker);
                        markers[index].marker = blueMarker;
                    }
                });

                // Add safety issue markers and info windows.
                jsonData.trip_plan.stops.forEach((stop, index) => {
                    if (stop.potential_safety_issues && stop.potential_safety_issues.length > 0) {
                        stop.potential_safety_issues.forEach((issue) => {
                            let safetyMarkerLocation = { lat: issue.position.latitude, lng: issue.position.longitude }
                            let safetyIcon = new H.map.Icon(alertMarker, { size: { w: 30, h: 35 } });
                            const safetyMarker = new H.map.Marker(safetyMarkerLocation, { icon: safetyIcon });
                            this.map.addObject(safetyMarker);
                            // Add a tap event listener to display safety issue details in an info window.
                            safetyMarker.addEventListener('tap', () => {
                                const infoWindowContent = `<div><b>${issue.description}</b><br /><a href="${issue.street_view_url}" target="_blank" rel="noopener noreferrer">Click for Street View</a></div>`;
                                const infoWindow = new H.ui.InfoBubble(safetyMarkerLocation, { content: infoWindowContent });
                                this.ui.addBubble(infoWindow);
                            });
                        });
                    }
                })
            }
        } catch (error) {
            console.error('Error drawing route on map:', error);
        }
    };

    handleClearButtonClick = () => {
        this.setState({ jsonData: null })
        this.setState({ fileError: false })
        this.props.onClearSnapShotData()
        // Remove map objects
        this.map.getObjects().forEach((obj) => {
            this.map.removeObject(obj);
        });
        // Clear the file input field
        if (this.fileInputRef.current) {
            this.fileInputRef.current.value = null;
        }
        this.setState({ uploadedFile: false })
        // Reset map zoom
        if (this.map) {
            this.map.setZoom(4.5);
            this.map.setCenter({ lat: 37.0902, lng: -95.7129 });
        }
    };

    render() {
        const { jsonData, mapInitialized } = this.state;

        //Helper function to order the stops based on stop number.
        const getSortedStops = (stops, load) => {
            const hasShipper = load && load.shipper && (load.shipper !== null);
            const hasConsignee = load && load.consignee && (load.consignee !== null);

            const compareStops = (a, b) => a.stop_number - b.stop_number;

            if (hasShipper && hasConsignee) {
                // Sort all stops including shipper and consignee
                const sortedStops = [load.shipper, ...stops, load.consignee].sort(compareStops);
                return sortedStops;
            } else if (hasShipper) {
                // Only shipper exists, sort stops and include shipper
                return [load.shipper, ...stops].sort(compareStops);
            } else if (hasConsignee) {
                // Only consignee exists, sort stops and include consignee
                return [...stops, load.consignee].sort(compareStops);
            } else {
                // Neither shipper nor consignee exists, sort only based on stop_number
                return stops.sort(compareStops);
            }
        };
        // Function to sort stops and update markers
        const sortStopsAndUpdateMarkers = (json) => {
            const stopArray = getSortedStops(json.load.stops, json.load);
            const markers = this.state.markers;
            const reasonCodes = this.props.reasonCodes;

            const getMarkerImage = (index, type) => {
                const marker = markers[index];
                let image;
                if (type === 'Green') {
                    image = marker && marker.isClicked ? filledGreenMarker : emptyGreenMarker;
                } else if (type === 'Blue') {
                    image = marker && marker.isClicked ? filledBlueMarker : emptyBlueMarker;
                } else if (type === 'Red') {
                    image = marker && marker.isClicked ? filledRedMarker : emptyRedMarker;
                }
                return image;
            };


            const addClickEventListenerToMarker = (latitude, longitude, index) => {
                const { lastClickedMarker, markers } = this.state;
                try {
                    if (lastClickedMarker === index) {
                        // Zoom out to the original route view when clicking the same marker again
                        this.map.getViewModel().setLookAtData({ bounds: this.state.routeBounds }, { opt_animate: true });
                        this.setState({ lastClickedMarker: null });
                        // Toggle the "isClicked" state of the current marker
                        const updatedMarkers = [...markers];
                        updatedMarkers[index].isClicked = !updatedMarkers[index].isClicked;
                        this.setState({ markers: updatedMarkers });
                    } else {
                        // Zoom into location of the clicked marker
                        this.map.getViewModel().setLookAtData({ position: { lat: latitude, lng: longitude }, zoom: 15 }, { opt_animate: true });
                        this.setState({ lastClickedMarker: index });

                        // Reset the "isClicked" state of the previously clicked marker (if any)
                        if (lastClickedMarker !== null && markers[lastClickedMarker]) {
                            const updatedMarkers = [...markers];
                            updatedMarkers[lastClickedMarker].isClicked = false;
                            this.setState({ markers: updatedMarkers });
                        }
                        // Set the "isClicked" state of the current marker to true
                        const updatedMarkers = [...markers];
                        updatedMarkers[index].isClicked = true;
                        this.setState({ markers: updatedMarkers });
                    }
                    // Update the marker's icon in the map
                    const marker = markers[index].marker;
                    if (marker) {
                        const type = index === 0 ? 'Green' : index === markers.length - 1 ? 'Red' : 'Blue';
                        const iconImage = getMarkerImage(index, type);
                        const icon = new H.map.Icon(iconImage, { size: { w: 40, h: 45 } });

                        // Remove the old marker from the map
                        this.map.removeObject(marker);

                        // Create a new marker with the updated icon and add it to the map
                        const updatedMarker = new H.map.Marker({ lat: latitude, lng: longitude }, { icon: icon });
                        this.map.addObject(updatedMarker);

                        // Update the marker reference in the state
                        const updatedMarkers = [...markers];
                        updatedMarkers[index].marker = updatedMarker;
                        this.setState({ markers: updatedMarkers });
                    }
                } catch (error) {
                    if (stopArray.length !== markers.length) {
                        this.setState({ fileError: true });
                        error = 'Invalid: The clicked stop, was not included in the request.'
                    }
                    alert(error)
                }
            };

            // Helper function to map reason codes to their descriptions
            const getReasonCodeDescriptions = (reasonCodes) => {
                if (!reasonCodes) return "";
                const reasonCodeMapping = {
                    "original": "First time the trip was viewed on a device.",
                    "reroute": "Reroute occurred.",
                    "driver_reroute": "Driver initiated reroute.",
                    "driver_reroute": "Driver initiated reroute.",
                    "system_reroute": "System initiated reroute.",
                    "changed_by_client": "Client changed stop data.",
                    "new_guidance_route": "Driver began navigating to specific stop.",
                    "add_avoid_safety_finding": "Driver avoided a difficult maneuver.",
                    "add_ignore_safety_finding": "Driver ignored a difficult manuever.",
                    "remove_avoid_safety_finding": "Driver removed an avoid difficult maneuver.",
                    "remove_ignore_safety_finding": "Driver removed an ignore difficult maneuver.",
                    "change_departure_time": "Driver changed departure time.",
                    "add_stop": "Driver added stop.",
                    "add_via": "Driver added via point.",
                    "delete_stop": "Driver deleted stop.",
                    "move_stop": "Driver reordered stops.",
                    "mute-policy": "Driver muted a policy.",
                    "change_stop_location": "Driver moved location pin.",
                    "avoid_safety_finding": "Driver avoided a difficult maneuver.",
                    "change_starting_stop": "Driver changed starting stop.",
                };
                const codes = reasonCodes.split(", ");
                return codes.map((code, index) => (
                    <span key={index}>
                        <span style={{ fontWeight: 'bold' }}>{code}: </span>
                        {reasonCodeMapping[code] || code}
                    </span>
                ));
            };

            const stopElements = stopArray.map((stop, index) => {
                const isStartMarker = index === 0;
                const isEndMarker = index === stopArray.length - 1;
                const markerImage = getMarkerImage(index, isStartMarker ? "Green" : isEndMarker ? "Red" : "Blue");

                return (
                    <Grid item xs={12} key={index} >
                        <Container component={Paper} style={{ margin: "5px", backgroundColor: "#F9F9F9" }} onClick={() => addClickEventListenerToMarker(stop.location.latitude, stop.location.longitude, index)}>
                            <Grid container alignItems="center" spacing={2}>
                                <Grid item xs={12}>
                                    <Typography>{stop.stop_type}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <img src={markerImage} height={"40px"} width={"50px"} />
                                </Grid>
                                <Grid item>
                                    <Typography><b>{stop.name}</b></Typography>
                                    <Typography>{stop.address}</Typography>
                                    <Typography>{stop.city}, {stop.state} {stop.postal_code}</Typography>
                                </Grid>
                                {stop.identifiers && stop.identifiers.length > 0 && (
                                    <Grid item>
                                        <Table size="small">
                                            <TableBody>
                                                {stop.identifiers.map((id, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell><b><i>{id.label}</i></b>:</TableCell>
                                                        <TableCell>{id.value}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                )}
                                <Grid item>
                                    <Table size="small">
                                        <TableBody>
                                            {stop.product && (<TableRow><TableCell><i><b>Product</b>:</i></TableCell><TableCell>{stop.product}</TableCell></TableRow>)}
                                            {stop.pieces && (<TableRow><TableCell><i><b>Pieces</b>:</i></TableCell><TableCell>{stop.pieces}</TableCell></TableRow>)}
                                            {stop.pallets && (<TableRow><TableCell><i><b>Pallets</b>:</i></TableCell><TableCell>{stop.pallets}</TableCell></TableRow>)}
                                            {stop.time_window && (<>
                                                <TableRow>
                                                    <TableCell><i><b>Time Window</b></i>:</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell><b>From:</b></TableCell>
                                                    <TableCell>{stop.time_window.from}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell><b>To:</b></TableCell>
                                                    <TableCell>{stop.time_window.to}</TableCell>
                                                </TableRow></>
                                            )}
                                        </TableBody>
                                    </Table>
                                </Grid>
                                {reasonCodes && !this.state.uploadedFile && (
                                    <Grid item>
                                        <Typography>
                                            <div style={{ margin: "10px 0" }}>
                                                <b>Reason(s):</b>
                                                <ul style={{ paddingInlineStart: "20px" }}>
                                                    {getReasonCodeDescriptions(reasonCodes).map((reason, index) => (
                                                        <li key={index}>{reason}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                            <br />
                        </Container>
                        {this.state.routingOpts[index] && (
                            <div>
                                <Accordion component={Paper} style={{ margin: "5px" }}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel-${index}-content`} id={`panel-${index}-header`}>
                                        <Typography style={{ display: "contents" }}><TuneIcon />&emsp;<b>Route Options</b></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            {this.state.routerEngine && this.state.routerEngine[index] && (<><u><b>Router Engine:</b></u> &emsp;&emsp;&thinsp;{this.state.routerEngine[index]}<br /></>)}
                                            <li style={{ listStyle: "none" }}><b>Truck Type:</b> {this.state.routingOpts[index].truck.type}</li>
                                            {this.state.routingOpts[index].truck.height && <li style={{ listStyle: "none" }}><b>Height {`${'(ft.)'}`}:</b> {this.state.routingOpts[index].truck.height}</li>}
                                            {this.state.routingOpts[index].truck.length && <li style={{ listStyle: "none" }}><b>Length {`${'(ft.)'}`}:</b> {this.state.routingOpts[index].truck.length}</li>}
                                            {this.state.routingOpts[index].truck.width && <li style={{ listStyle: "none" }}><b>Width {`${'(ft.)'}`}:</b> {this.state.routingOpts[index].truck.width}</li>}
                                            {this.state.routingOpts[index].truck.limited_weight && <li style={{ listStyle: "none" }}><b>Limited Weight {`${'(lbs.)'}`}:</b> {this.state.routingOpts[index].truck.limited_weight}</li>}
                                            <li style={{ listStyle: "none" }}><b>Trailers:</b> {this.state.routingOpts[index].truck.trailer_count}</li>
                                            <hr />
                                            <Accordion>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel-${index}-content`} id={`panel-${index}-header`}>
                                                    <Typography><b>Avoids</b></Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        <li style={{ listStyle: "none" }}><b>Avoid Tunnels:</b> {this.state.routingOpts[index].avoid_tunnels ? "✓" : "✕"}</li>
                                                        <li style={{ listStyle: "none" }}><b>Avoid Toll Roads:</b> {this.state.routingOpts[index].avoid_toll_roads ? "✓" : "✕"}</li>
                                                        <li style={{ listStyle: "none" }}><b>Avoid Dirt Roads:</b> {this.state.routingOpts[index].avoid_dirt_roads ? "✓" : "✕"}</li>
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        )}
                    </Grid>
                );
            });

            return (
                <Grid container spacing={2}>
                    {stopElements}
                </Grid>
            )
        }

        //Display the Generated At Time.
        let generatedAtTime = null;
        if (jsonData && jsonData.generated_at) {
            const generatedAt = new Date(jsonData.generated_at);
            const options = { timeZoneName: 'short', hour12: false };
            generatedAtTime = generatedAt.toLocaleString("en-US", options);
        }
        const handleDownloadSnapshot = () => {
            const loadJson = JSON.stringify(jsonData);
            if (loadJson) {
                const blob = new Blob([loadJson], { type: 'application/json' });
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${jsonData.load.id}_SnapshotData.json`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            }
        };
        const sanitizeObject = (object, object_properties) => {
            for (var key in object) {
                if (object.hasOwnProperty(key) && object_properties.includes(key) == false) {
                    delete object[key]
                }
            }
        }
        const sanitizeStop = (stop, stop_properties, truck_properties, route_options_properties) => {
            sanitizeObject(stop, stop_properties)
            if (stop.truck != null) {
                sanitizeObject(stop.truck, truck_properties)
            }
            if (stop.route_options != null) {
                sanitizeObject(route_options, route_options_properties)
            }
        }

        // a heuristic approach to make copying and pasting load json into airtable easier for testing
        const extractLoadJson = () => {
            let truck_properties = ["type", "height", "width", "length", "limited_weight", "weigth_per_axle", "trailer_count", "tunnel_category", "hazardous_types", "traffic_rerouting"];
            let stop_properties = ["stop_number", "stop_type", "current", "name", "address", "city", "state", "postal_code", "location", "identifiers", "truck", "route_options"];
            let load_properties = ["id", "display_identifier", "sort", "order_number", "load_status", "load_status_label", "active", "current", "trip_planner_enabled", "route_options", "stops", "consignee", "truck", "shipper", "route_options"];
            let route_options_properties = ["avoid_dirt_roads", "avoid_toll_roads", "avoid_tunnels", "hide_total_miles"];
            let resp = jsonData;
            let load = resp.load;
            if (load) {
                sanitizeObject(load, load_properties);
                if (load.stops != null) {
                    for (let s = 0; s < load.stops.length; s++) {
                        sanitizeStop(load.stops[s], stop_properties, truck_properties, route_options_properties);
                    }
                }
                if (load.shipper != null) {
                    sanitizeStop(load.shipper, stop_properties, truck_properties, route_options_properties);
                }
                if (load.consignee != null) {
                    sanitizeStop(load.consignee, stop_properties, truck_properties, route_options_properties);
                }
                if (load.truck != null) {
                    sanitizeObject(load.truck, truck_properties);
                }
                if (load.route_options != null) {
                    sanitizeObject(route_options, route_options_properties);
                }
            }
            return JSON.stringify([load]);
        }
        const handleDownloadLoad = async () => {
            const loadJson = await extractLoadJson();
            if (loadJson) {
                const blob = new Blob([loadJson], { type: 'application/json' });
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${jsonData.load.id}.json`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            }
        };
        const handleDownloadGPX = () => {
            const loadJson = jsonData;
            if (loadJson) {
                //Create a GPX File utilizing all geometry segments
                const geometryPoints = loadJson.trip_plan.stops.flatMap(stop => {
                    if (stop.geometry_segments) {
                        return stop.geometry_segments.flatMap(segment => {
                            if (segment.geometry) {
                                return segment.geometry;
                            }
                            return [];
                        });
                    }
                    return [];
                });
                const stopArray = getSortedStops(loadJson.load.stops, loadJson.load)

                const gpxContent =
                    `<?xml version="1.0" encoding="UTF-8" standalone="no" ?>
  <gpx xmlns="http://www.topografix.com/GPX/1/1" xmlns:gpxx="http://www.garmin.com/xmlschemas/GpxExtensions/v3" xmlns:gpxtpx="http://www.garmin.com/xmlschemas/TrackPointExtension/v1" creator="mapstogpx.com" version="1.1" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.topografix.com/GPX/1/1 http://www.topografix.com/GPX/1/1/gpx.xsd http://www.garmin.com/xmlschemas/GpxExtensions/v3 http://www.garmin.com/xmlschemas/GpxExtensionsv3.xsd http://www.garmin.com/xmlschemas/TrackPointExtension/v1 http://www.garmin.com/xmlschemas/TrackPointExtensionv1.xsd">
    <wpt lat="${stopArray[0].location.latitude}" lon="${stopArray[0].location.longitude}">
      <name>${stopArray[0].name}</name>
    </wpt>
    <wpt lat="${stopArray[stopArray.length - 1].location.latitude}" lon="${stopArray[stopArray.length - 1].location.longitude}">
      <name>${stopArray[stopArray.length - 1].name}</name>
    </wpt>
    <trk>
      <name>${loadJson.load.order_number}</name>
      <trkseg>
      ${geometryPoints.map((point, index) =>
                        `\t<trkpt lat="${point.latitude}" lon="${point.longitude}"><name>TP${index}</name></trkpt>`
                    ).join('\n\t')}
      </trkseg>
    </trk>
  </gpx>`;
                const blob = new Blob([gpxContent], { type: 'application/gpx+xml' });
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${loadJson.load.id}.gpx`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            }
        }
        return (
            <div style={{ display: 'flex', marginLeft: '-30px' }}>
                <Container component={Paper} >
                    <div label="uploadJSON">
                        <FormControl>
                            <Input id="uploadJSON" type="file" error={this.state.fileError} inputRef={this.fileInputRef} onChange={this.handleFileUpload} />
                            {this.state.fileError && <FormHelperText error="true">Invalid JSON data format.</FormHelperText>}
                        </FormControl>
                    </div>
                    {jsonData &&
                        <div style={{ marginTop: "20px" }}>
                            <Typography variant="h5"><b>Load Information:</b></Typography>
                            {generatedAtTime && (
                                <Typography><b><u>Generated At:</u></b><br /> {generatedAtTime}</Typography>
                            )}
                            <hr />
                            <Typography><b><u>Load ID:</u></b><br /> {jsonData.load.id}</Typography>
                            <Typography><b><u>Order Number:</u></b><br /> {jsonData.load.order_number}</Typography>
                            <Typography><b><u>Status:</u></b><br /> {jsonData.load.load_status}</Typography>
                            <Typography><b><u>Username:</u></b><br /> {jsonData.username}</Typography>
                            {jsonData.trip_plan.fallback_router && (
                                <>
                                    <br />
                                    <Typography><b>Pluggable Router Failed!</b></Typography>
                                    <Typography><b><u>Routing Engine:</u></b><br />{"HERE"}</Typography>
                                </>
                            )}
                            <br /><br />
                            {sortStopsAndUpdateMarkers(jsonData)}
                        </div>
                    }
                    {mapInitialized && !jsonData && (
                        <Typography>No route data available. Please upload a Load JSON file <b>with geometry</b>.</Typography>
                    )}
                    {jsonData &&
                        <Table>
                            <TableRow>
                                <TableCell><Typography style={{ display: "inline", fontSize: "small" }} id="snapshot-download">Download Snapshot: <IconButton color="primary" size="small" onClick={handleDownloadSnapshot}><GetAppIcon /></IconButton></Typography></TableCell>
                                <TableCell><Typography style={{ display: "inline", fontSize: "small" }} id="load-download">Download Load: <IconButton color="primary" size="small" onClick={handleDownloadLoad}><GetAppIcon /></IconButton></Typography></TableCell>
                                <TableCell><Typography style={{ display: "inline", fontSize: "small" }} id="gpx-download">Download GPX: <IconButton color="primary" size="small" onClick={handleDownloadGPX}><GetAppIcon /></IconButton></Typography></TableCell>
                            </TableRow>
                        </Table>
                    }
                    <div style={{ margin: '10px' }} label="clearJSON">
                        <Button id="clear-json-btn" variant="contained" size="medium" onClick={this.handleClearButtonClick}>{" Clear "}</Button>
                    </div>
                </Container>
                <Container component={Paper}
                    id="map-container"
                    ref={this.mapRef}
                    style={{
                        width: "275%",
                        height: "1000px",
                        marginTop: "60px",
                        overflow: "hidden",
                        touchAction: "none",
                        userSelect: "none",
                        MozUserSelect: "none",
                        WebkitUserSelect: "none",
                        MsUserSelect: "none",
                    }}
                />
            </div>
        );
    }
}
