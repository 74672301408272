import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  DateTimeInput,
  AutocompleteInput,
  required,
  useGetManyReference,
  NotFound,
  useCreatePath,
  useRecordContext,
  useUpdate,
  useRefresh,
  useGetList,
  Loading,
} from "react-admin";
import { useSearchParams, Link as RouterLink } from "react-router-dom";
import { Button as MaterialButton } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import HowToRegIcon from "@mui/icons-material/HowToReg";
import DraftsTable from "../components/DraftsTable";
import { CreateUnsubscribeDraft } from "../documentation/CustomerUnsubscribeDoc";

export const UnsubscribeButton = () => {
  const record = useRecordContext();
  const createPath = useCreatePath();

  return (
    <MaterialButton
      id="unsubscribe-button"
      color="error"
      style={{ fontSize: "0.8125rem", padding: ".15rem" }}
      component={RouterLink}
      to={
        createPath({
          resource: "customer_unsubscribe_drafts",
          type: "create",
        }) + `?uuid=${encodeURIComponent(record.id)}`
      }
    >
      <CancelIcon style={{ fontSize: "1.25rem", paddingRight: "0.5rem" }} />
      Unsubscribe
    </MaterialButton>
  );
};

const PreserveBackendUserInput = ({ uuid }) => {
  const { data, isLoading } = useGetManyReference("backend_users", {
    target: "uuid",
    id: uuid,
  });

  if (isLoading) return <></>;

  return (
    <AutocompleteInput
      source="preserve_backend_user_email"
      choices={data}
      optionText="email"
      validate={required()}
      style={{ width: 280 }}
    />
  );
};

export const CustomerUnsubscribeDraftsCreate = () => {
  const [searchParams] = useSearchParams();
  const uuid = searchParams.get("uuid");
  const createPath = useCreatePath();

  const validateDateNotInPast = (value) => {
    const date = new Date();
    const currentDate = date.toISOString();
    const futureDate = new Date(date.getTime() + 24 * 60 * 60 * 1000).toISOString()
    if (value < currentDate) {
      return 'Date and time cannot be right now or in the past';
    }
    else if(value < futureDate) {
      return 'Date and time must be at least 24 hours from now.'
    }
    return undefined;
  };

  if (uuid == null) return <NotFound />;

  return (
    <Create
      title={`Unsubscribe customer ${uuid}`}
      redirect={createPath({ resource: "customers", id: uuid, type: "show" }) + "?draft_created="}
      aside={<CreateUnsubscribeDraft />}
    >
      <SimpleForm>
        <TextInput source="uuid" defaultValue={uuid} style = {{ width: 330}} disabled  />
        <PreserveBackendUserInput uuid={uuid} />
        <DateTimeInput label="Unsubscribe Date (optional)" source="unsubscribeDate" parse={(date) => (date ? new Date(date).toISOString() : null)} style = {{ width: 280}} fullWidth={true} validate={[validateDateNotInPast]}/>
      </SimpleForm>
    </Create>
  );
};

const ApproveDraftButton = () => {
  const record = useRecordContext();
  const [update, { isLoading, error }] = useUpdate(
    "customer_unsubscribe_drafts",
    {
      id: record.id
    }
  );
  const refresh = useRefresh();

  const handleClick = () => {
    update();
    refresh();
  };

  if (error) return <></>;
  if (isLoading) return <></>;

  const current_user_email = localStorage.getItem("user_email");

  return (
    <MaterialButton
      id={`approve-button-${record.id}`}
      color="primary"
      onClick={handleClick}
      style={{ fontSize: "0.8125rem", padding: ".15rem" }}
      disabled={current_user_email === record.superuser_email}
    >
      <HowToRegIcon style={{ fontSize: "1.25rem", paddingRight: "0.5rem" }} />
      Approve
    </MaterialButton>
  );
};
export const CustomerUnsubscribeDraftsField = () => {
  const record = useRecordContext();
  const { data, isLoading, error } = useGetList(
    "customer_unsubscribe_drafts",
    {
      filter: { "event.path": `/${record.id}` },
      pagination: { page: 1, perPage: 10 },
      headers: {
        "acl": "public-read"
      }
    }
  );
  if (isLoading) { return <Loading /> }
  if (error) { return <p>ERROR</p> }
  return (
    <DraftsTable data={data} resource={"customer_unsubscribe_drafts"} />
  );
};
