import React from "react";
import {
    Datagrid, TextField, NumberField,
    DateField, Tab, TabbedShowLayout,
    useGetOne, useRecordContext, UrlField, SimpleShowLayout,
    ArrayField,
    EmailField
} from "react-admin";
import { useParams } from 'react-router-dom';
import { Typography } from "@mui/material";
import { CheckSharp, Clear } from "@mui/icons-material";
import { DollarField } from "./customers";


export const StatusField = ({ status}) => {
    const badgeStyles = {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '8em',
      borderRadius: '8px',
      backgroundColor: status === 'succeeded' ? '#e0f7e9' : '#f8d7da',
    };
    const textStyles = {
      color: status === 'succeeded' ? '#1b5e20' : '#721c24',
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '16px',
      marginRight: '4px',
      display: 'flex',
      alignItems: 'center',
    };
    const iconColor = status === 'succeeded' ? '#22BB33' : '#BB2124';
    const Icon = status === 'succeeded' ? <CheckSharp style={{ color: iconColor }} /> : (status === 'failed' ? <Clear style={{ color: iconColor }} /> : null);
  
    return (
      <div style={badgeStyles}>
        {Icon && <span style={textStyles}>{Icon}</span>}
        <span style={textStyles}>{status}</span>
      </div>
    );
  };

export const ChargeShow = () => {
    const { customer_uuid, charge_uuid } = useParams();
    const { data, isLoading } = useGetOne('charges', { id: [customer_uuid, charge_uuid] })
    if (isLoading) return null
    return (
      <TabbedShowLayout record={data} spacing={2} syncWithLocation={false}>
        <Tab label="Charge">
          <TextField source="status" />
          <DateField source="created_at" showTime />
          <DateField source="updated_at" showTime />
          <DollarField label="Amount" {...data} />
          <TextField label="Last 4 Digits of Card" source="last_four" />
          <TextField source="description" />
          <UrlField label="Payment Page" source="payment_page" target="_blank" rel="noopener noreferrer" emptyText="no url found" />
          <TextField label="Stripe Invoice ID" source="stripe_invoice_id" />
          <TextField label="Stripe Event ID" source="stripe_event_id" />
        </Tab>
      </TabbedShowLayout>
    );
  }
  
export const CardExpirationField = (props) => {
    const data = useRecordContext(props);
    const month = data.card_info.exp_month;
    const year = data.card_info.exp_year;
    const expirationDate = `${month}/${year}`;
    return (<Typography component="span" variant="body2">{expirationDate}</Typography>);
  };
  
export const PeriodField = (props) => {
    const data = useRecordContext(props);
    const start = data.current_period_start;
    const end = data.current_period_end;
    const startDate = new Date(start * 1000);
    const endDate = new Date(end * 1000);
    const startDateString = startDate.toLocaleDateString();
    const endDateString = endDate.toLocaleDateString();
    const period = `${startDateString} - ${endDateString}`;
    return (<Typography component="span" variant="body2">{period}</Typography>);
  }
  
export const SubscriptionPriceField = (props) => {
    const data = useRecordContext(props);
    const interval = data.plan_interval;
    const dollar_amount =
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(data.amount / 100);
    const plan_price = `${dollar_amount} USD / ${interval}`
    return (<Typography component="span" variant="body2">{plan_price}</Typography>);
  }
  
export const SubscriptionProductField = (props) => {
    const data = useRecordContext(props);
    const name = data.product.name
    const plan = data.plan_id
    const product = `${name} • ${plan}`
    return (<Typography component="span" variant="body2">{product}</Typography>);
  }
  
export const InvoiceSubscriptionShow = ({ id }) => {
    const { data, isLoading, error } = useGetOne('stripe_subscriptions', { id });
  
    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;
    return (
      <SimpleShowLayout record={data}>
        <DateField label="Created" source="created" />
        <PeriodField label="Current Period" {...data} />
        <div style={{marginTop:'2em', display:'inline-block'}}>
          <Typography style={{fontFamily:'var(--sail-font-family)', fontSize:'20px', fontWeight:'bold'}}>Pricing</Typography>
          <ArrayField source="items">
            <Datagrid size="large" bulkActionButtons={false} sort={false}>
              <SubscriptionProductField label="Product" {...data} />
              <NumberField source="quantity" />
              <SubscriptionPriceField label="Amount" {...data} />
            </Datagrid>
          </ArrayField>
        </div>
      </SimpleShowLayout>
    );
  };
  
export const StripeChargeShow = () => {
    const { stripe_charge_id } = useParams();
    const { data, isLoading } = useGetOne('stripe_charges', { id: stripe_charge_id });

    if (isLoading) return null;

    const {
        status,
        created,
        amount,
        outcome,
        description,
        invoice_id,
        card_info,
        payment_page,
        receipt_email
    } = data || {};

    return (
        <TabbedShowLayout record={data} spacing={2} syncWithLocation={false}>
        <Tab label="Charge">
            {status && <TextField source="status" />}
            {created && <DateField source="created" showTime />}
            {amount && <DollarField label="Amount" {...data} />}
            {receipt_email && <EmailField source="receipt_email" />}
            {outcome && outcome.type && <TextField source="outcome.type" />}
            {outcome && outcome.reason && <TextField source="outcome.reason" />}
            {outcome && outcome.seller_message && <TextField source="outcome.seller_message" />}
            {description && <TextField source="description" />}
            {payment_page && <UrlField
                label="Payment Page"
                source="payment_page"
                target="_blank"
                rel="noopener noreferrer"
                emptyText="no url found"
            />}
            {invoice_id && <TextField label="Stripe Invoice ID" source="invoice_id" />}
        </Tab>
        <Tab label="Card Info">
            {card_info && card_info.brand && <TextField label="Card Brand" source="card_info.brand" />}
            {card_info && card_info.last4 && <TextField label="Last 4 Digits" source="card_info.last4" />}
            {card_info && <CardExpirationField label="Expiration Date" {...data} />}
            {card_info && card_info.address_line1_check && <TextField label="Address Line 1 Check" source="card_info.address_line1_check" />}
            {card_info && card_info.address_postal_code_check && <TextField label="Address Postal Code Check" source="card_info.address_postal_code_check" />}
            {card_info && card_info.cvc_check && <TextField label="CVC Check" source="card_info.cvc_check" />}
        </Tab>
        {invoice_id && 
            <Tab label="Subscription Info">
            <InvoiceSubscriptionShow id={invoice_id} />
            </Tab>
        }
        </TabbedShowLayout>
    );
};