import { Box, CircularProgress, Grid, Stack } from "@mui/material";

export const CheckingPerms = () => (
    <Box sx={{ width: '100%' }}>
        <Stack spacing={2} >
            <h4 style={{ textAlign: 'center' }}>Checking Permissions...</h4>
            <div style={{ textAlign: 'center', height: '100vh' }}><CircularProgress /></div>
        </Stack>
    </Box>
)

export const LoadingList = ({ resource }) => {
    return (
        <Box sx={{ width: '100%' }}>
            <Stack spacing={2} >
                <h4 style={{ textAlign: 'center' }}>{`Loading ${resource}...`}</h4>
                <div style={{ textAlign: 'center', height: '100vh' }}><CircularProgress /></div>
            </Stack>
        </Box>
    )
}