// in src/Menu.js
import * as React from 'react';
import DirectionsIcon from '@mui/icons-material/Directions';
import { useMediaQuery, MenuItem, CircularProgress, List, ListItemIcon, ListItemText, Collapse } from '@mui/material';
import { MenuItemLink, Menu, useSidebarState, usePermissions } from 'react-admin';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { Paid, ExpandLess, ExpandMore, Phonelink } from '@mui/icons-material';
import { styled } from '@mui/system';
import { renderIfPermissions } from './api/permissionManager';
import { CheckingPerms } from './components/LoadingComponent';

// Styled components
const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  paddingTop: "12px",
  paddingBottom: "12px",
  color: theme.palette.text.primary,
  '&.no-expand': {
    paddingTop: "16px",
    paddingBottom: "16px",
  },
}));

const StyledMenuItemLink = styled(MenuItemLink)(({ theme }) => ({
  padding: "16px",
  paddingLeft: "34px",
  color: theme.palette.text.secondary,
}));

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: "40px",
}));

const CustomMenu = ({ onMenuClick, logout }) => {
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const [open] = useSidebarState();
  const [platExpand, setPlatExpand] = React.useState(true);
  const [axleExpand, setAxleExpand] = React.useState(true);
  const [billingExpand, setBillingExpand] = React.useState(true);
  const [routingExpand, setRoutingExpand] = React.useState(true);
  const [permissionsExpand, setPermissionsExpand] = React.useState(true);

  const { permissions, isLoading } = usePermissions();

  const handlePlatform = () => setPlatExpand(!platExpand);
  const handleBilling = () => setBillingExpand(!billingExpand);
  const handleDriveAxle = () => setAxleExpand(!axleExpand);
  const handleRouting = () => setRoutingExpand(!routingExpand);
  const handlePermissions = () => setPermissionsExpand(!permissionsExpand);

  const hasVisibleBillingResources = () => {
    const platformResources = ['billing', 'billing_accounts'];
    return platformResources.some(resource => renderIfPermissions(permissions, resource, "read_only", <div />) !== null);
  }
  const hasVisiblePlatformResources = () => {
    const platformResources = ['clients', 'client_groups', 'apps'];
    return platformResources.some(resource => renderIfPermissions(permissions, resource, "read_only", <div />) !== null);
  };
  const hasVisibleDriveAxleResources = () => {
    const platformResources = ['customers', 'documents'];
    return platformResources.some(resource => renderIfPermissions(permissions, resource, "read_only", <div />) !== null);
  };
  const hasVisibleRoutingResources = () => {
    const platformResources = ['routing', 'client_trip_plans'];
    return platformResources.some(resource => renderIfPermissions(permissions, resource, "read_only", <div />) !== null);
  };

  const renderMenuItems = () => {
    return (
      <Menu>
        {isLoading && <CheckingPerms />}
        <List sx={{ paddingTop: '0' }}>
          {hasVisiblePlatformResources() && (
            <StyledMenuItem className={platExpand ? '' : 'no-expand'} onClick={handlePlatform} divider>
              <StyledListItemIcon><Phonelink /></StyledListItemIcon>
              <ListItemText primary="Platform" />
              {platExpand ? <ExpandLess /> : <ExpandMore />}
            </StyledMenuItem>
          )}
          <Collapse in={platExpand} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {renderIfPermissions(permissions, "clients", "read_only",
                <StyledMenuItemLink
                  key={'clients'}
                  to={`/clients`}
                  primaryText={"Clients"}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                />)}
              {renderIfPermissions(permissions, "client_groups", "read_only",
                <StyledMenuItemLink
                  key={'client_groups'}
                  to={`/client_groups`}
                  primaryText={"Client Groups"}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                />)}
              {renderIfPermissions(permissions, "apps", "read_only",
                <StyledMenuItemLink
                  key={'apps'}
                  to={`/apps`}
                  primaryText={"Apps"}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                />)}
              {renderIfPermissions(permissions, "apps", "write",
                <StyledMenuItemLink
                  key={'bulk_triggers'}
                  to={'/bulk_triggers'}
                  primaryText={"Bulk Trigger"}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                />)}
              {renderIfPermissions(permissions, "clients", "write",
                <StyledMenuItemLink
                  key={'error_reports'}
                  to={`/error_reports`}
                  primaryText={"Error Reports"}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                />)}
            </List>
          </Collapse>

          {hasVisibleBillingResources() && (
              <StyledMenuItem className={billingExpand ? '' : 'no-expand'} onClick={handleBilling} divider={true}>
                <StyledListItemIcon ><Paid /></StyledListItemIcon>
                <ListItemText primary="Billing" />
                {billingExpand ? <ExpandLess /> : <ExpandMore />}
              </StyledMenuItem>)}
              <Collapse in={billingExpand} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {renderIfPermissions(permissions, "billing_accounts", "read_only",<StyledMenuItemLink
                    key={'billing_accounts'}
                    to={`/billing_accounts`}
                    primaryText={"Billing Accounts"}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                  />)}
                  {renderIfPermissions(permissions, "billing", "read_only",<StyledMenuItemLink
                    key={'contract_structures'}
                    to={`/contract_structures`}
                    primaryText={"Contract Structures"}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                  />)}
                  {renderIfPermissions(permissions, "billing", "read_only", <StyledMenuItemLink
                    key={'price_sheets'}
                    to={`/price_sheets`}
                    primaryText={"Price Sheets"}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                  />)}
                  {renderIfPermissions(permissions, "billing", "read_only", <StyledMenuItemLink
                    key={'products'}
                    to={`/products`}
                    primaryText={"Products"}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                  />)}
                </List>
              </Collapse>

          {hasVisibleDriveAxleResources() && (
            <StyledMenuItem className={axleExpand ? '' : 'no-expand'} onClick={handleDriveAxle} divider>
              <StyledListItemIcon><DescriptionOutlinedIcon /></StyledListItemIcon>
              <ListItemText primary="Drive Axle" />
              {axleExpand ? <ExpandLess /> : <ExpandMore />}
            </StyledMenuItem>
          )}
          <Collapse in={axleExpand} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {renderIfPermissions(permissions, "customers", "read_only",
                <StyledMenuItemLink
                  key={'customers'}
                  to={`/customers`}
                  primaryText={"Hub Customers"}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                />)}
              {renderIfPermissions(permissions, "customers", "read_only",
                <StyledMenuItemLink
                  key={'scanning_users'}
                  to={`/scanning_users`}
                  primaryText={"Drive Axle Users"}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                />)}
              {renderIfPermissions(permissions, "documents", "read_only",
                <StyledMenuItemLink
                  key={'documents'}
                  to={'/documents'}
                  primaryText={"Get Documents"}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                />)}
            </List>
          </Collapse>

          {hasVisibleRoutingResources() && (
            <StyledMenuItem className={routingExpand ? '' : 'no-expand'} onClick={handleRouting} divider>
              <StyledListItemIcon><DirectionsIcon /></StyledListItemIcon>
              <ListItemText primary="Routing" />
              {routingExpand ? <ExpandLess /> : <ExpandMore />}
            </StyledMenuItem>
          )}
          <Collapse in={routingExpand} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {renderIfPermissions(permissions, "client_trip_plans", "read_only",
                <StyledMenuItemLink
                  key={'trip_snapshots'}
                  to={`/trip_snapshots`}
                  primaryText={"Trip Snapshots"}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                />)}
              {renderIfPermissions(permissions, "routing", "write",
                <StyledMenuItemLink
                  key={'pluggable_routes'}
                  to={`/pluggable_routes`}
                  primaryText={"Pluggable Routes"}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                />)}
              {renderIfPermissions(permissions, "routing", "write",
                <StyledMenuItemLink
                  key={'gpx_files'}
                  to={`/gpx_files`}
                  primaryText={"GPX Files"}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                />)}
            </List>
          </Collapse>

          {renderIfPermissions(permissions, "permissions", "write",
            <StyledMenuItem className={permissionsExpand ? '' : 'no-expand'} onClick={handlePermissions} divider>
              <StyledListItemIcon><DescriptionOutlinedIcon /></StyledListItemIcon>
              <ListItemText primary="Permissions" />
              {permissionsExpand ? <ExpandLess /> : <ExpandMore />}
            </StyledMenuItem>
          )}
          {renderIfPermissions(permissions, "permissions", "write",
            <Collapse in={permissionsExpand} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <StyledMenuItemLink
                  key={'permissions'}
                  to={`/internal_permissions`}
                  primaryText={"Permissions Management"}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                />
              </List>
            </Collapse>
          )}
        </List>
      </Menu>
    );
  }

  if (open) {
    return (
      <div>
        {renderMenuItems()}
        {isXSmall && logout}
      </div>
    );
  } else {
    return <div></div>;
  }
}

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}

export default withRouter(CustomMenu);
