import React from 'react';
import { AppBar, Layout, Logout, UserMenu } from 'react-admin';
import { styled } from '@mui/system';
import Typography from '@mui/material/Typography';
import { defaultTheme } from 'react-admin';
import CustomMenu from '../CustomMenu'
import { ENVIRONMENT } from '../config';
import { Tooltip, Box, Popover, MenuItem } from '@mui/material';
import { Button } from '@mui/material';
import { Visibility } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom'
import ReadWriteIcon from '@mui/icons-material/Create';
import ReadOnlyIcon from '@mui/icons-material/Visibility';
import NoAccessIcon from '@mui/icons-material/Clear';
import HomeIcon from '@mui/icons-material/Home';

const stagingTheme = {
    ...defaultTheme,
    palette: {
        secondary: {
            main: '#2196f3'
        }
    },
    components: {
        ...defaultTheme.components,
        RaMenuItemLink: {
            styleOverrides: {
                root: {
                    '&.RaMenuItemLink-active': {
                        color: '#3f51b5',
                    }
                }
            },
        }

    }
}


const prodTheme = {
    ...defaultTheme,
    palette: {
        secondary: {
            main: '#f50057'
        },
        primary: {
            main: '#b2102f'
        }
    },
    components: {
        ...defaultTheme.components,
        RaMenuItemLink: {
            styleOverrides: {
                root: {
                    '&.RaMenuItemLink-active': {
                        color: '#b2102f',
                    }
                },
            }
        }
    }
}


const TitleTypography = styled(Typography)(({ theme }) => ({
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
}));

const CustomButton = styled(Button)(({ theme }) => ({
    paddingTop: 12,
    margin: 1,
    paddingRight: 24,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    color: 'white',
    border:'none',
    borderColor: 'none'
}));

const CustomHomeMenuButton = styled(Button)(({ theme }) => ({
    '-webkit-tap-highlight-color': 'transparent',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    userSelect: 'none',
    verticalAlign: 'middle',
    appearance: 'none',
    color: 'inherit',
    fontFamily: 'Gabarito, tahoma, sans-serif',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '1.5',
    display: 'flex',
    '-webkit-box-pack': 'start',
    justifyContent: 'flex-start',
    '-webkit-box-align': 'center',
    alignItems: 'center',
    position: 'relative',
    textDecoration: 'none',
    minHeight: '48px',
    padding: '6px 16px',
    boxSizing: 'border-box',
    whiteSpace: 'nowrap',
    border:'none'
}));


const MyUserMenu = (props) => (
    <UserMenu {...props}>
        <MenuItem>
            <Typography variant='subtitle2'>
                {localStorage.getItem("user_email") !== undefined ? `Hello, ${localStorage.getItem("user_email")}!` : null}
            </Typography>
        </MenuItem>
        <Logout />
    </UserMenu>
)

const getPermissions = (setLoading, setPermissionsDisplay) => {
    setLoading(true);
    const granularPermissions = localStorage.getItem('granular_permissions');
    if (granularPermissions) {
        const permissions = JSON.parse(granularPermissions);
        const { authentication, ...otherPermissions } = permissions
        const display = Object.entries(otherPermissions).map(([resource, perms]) => {
            if (resource === 'email') return null; // Skip the email field

            let accessLevel;
            if (perms.write) {
                accessLevel = <ReadWriteIcon />;
            } else if (perms.read_only) {
                accessLevel = <ReadOnlyIcon />;
            } else {
                accessLevel = <NoAccessIcon style={{ color: 'red' }} />;
            }

            return (
                <div key={resource} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '1em' }}>
                    <strong style={{ marginRight: '1em' }}>{resource}:</strong>
                    {accessLevel}
                </div>
            );
        }).filter(Boolean);

        setPermissionsDisplay(display);
    } else {
        const platformPermissions = localStorage.getItem("permissions");
        const billingPermissions = localStorage.getItem("billing_permissions");
        const permissionsPermissions = localStorage.getItem("permissions_management");

        setPermissionsDisplay([
            <p key="platform"><strong>{platformPermissions || 'Reading permissions...'}</strong> Platform permissions</p>,
            <p key="billing"><strong>{billingPermissions || 'Reading permissions...'}</strong> Billing permissions</p>,
            <p key="permissions"><strong>{permissionsPermissions || 'Reading permissions...'}</strong> Permissions management</p>
        ]);
    }
    setLoading(false);
};

const CustomAppBar = (props) => {
    const [elementPosition, setElementPosition] = React.useState(null);
    const [permissionsDisplay, setPermissionsDisplay] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const navigate = useNavigate();

    const handlePermissionsClick = (event) => {
        setElementPosition(elementPosition ? null : event.currentTarget);
        getPermissions(setLoading, setPermissionsDisplay);
    };

    const handleClose = () => {
        setElementPosition(null);
    };

    const handleHomeClick = () => {
        navigate('/');
    };

    const openPermissions = Boolean(elementPosition);
    const permissionsId = openPermissions ? 'permissions-popper' : undefined;
    return (
        <AppBar {...props} userMenu={<MyUserMenu />} style={{backgroundColor: ENVIRONMENT === 'staging' ? '#2196f3' : '#b2102f'}}>
            <TitleTypography variant="h6" id="react-admin-title" />
            <div style={{ padding: 'inherit', textTransform:'uppercase' }}><b>{ENVIRONMENT}</b></div>
            <Tooltip title="Permissions">
                <CustomButton
                    label="My Permissions"
                    endIcon={<Visibility />}
                    aria-haspopup="true"
                    aria-expanded={openPermissions ? 'true' : undefined}
                    style={{ paddingBlock: 'initial' }}
                    onClick={handlePermissionsClick}
                >
                    Permissions
                </CustomButton>
            </Tooltip>
            <Popover
                id={permissionsId}
                open={openPermissions}
                anchorEl={elementPosition}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box m={1}>
                    <div>
                        {loading ? (<p>Loading permissions...</p>) : permissionsDisplay}
                    </div>
                </Box>
            </Popover>
            <Tooltip title="Home Page">
                <CustomButton style={{ padding: 'inherit' }} label='home' endIcon={<HomeIcon />} onClick={handleHomeClick}>
                    Home
                </CustomButton>
            </Tooltip>
        </AppBar>
    );
};

const CustomAppLayout = (props) => <Layout {...props} appBar={CustomAppBar} menu={CustomMenu} />

const currentTheme = ENVIRONMENT === 'staging' ? stagingTheme : prodTheme;

export {
    CustomAppBar,
    CustomAppLayout,
    currentTheme
};