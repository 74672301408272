import React from "react";
import {
  useRecordContext,
  ListBase,
  Datagrid,
  TextField,
  DateField,
} from "react-admin";

export const BackendUserEmailVerificationsField = () => {
  const record = useRecordContext();

  return (
    <ListBase
      resource="email_verifications"
      exporter={false}
      filter={{ email: record.id }}
    >
      <Datagrid bulkActionButtons={false}>
        <DateField source="verification_sent_at" showTime sortable={false} />
        <DateField source="verified_at" showTime sortable={false} />
        <TextField source="message" sortable={false} />
        <TextField source="admin_email" sortable={false} />
      </Datagrid>
    </ListBase>
  );
};