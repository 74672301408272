import React, { useState } from "react";
import { Button, Typography, Divider } from "@mui/material";
import { DialogContent, DialogActions, Dialog } from '@mui/material';
import { useNotify } from 'react-admin';

export const ResetPasswordButton = ({record, ...props}) => {
  const notify = useNotify();
  const [open, setOpen] = useState(false);

  const API_URL = (window.frameElement && window.frameElement.getAttribute("data-api-url")) || (process && process.env && process.env.REACT_APP_API_URL);

  const passwordResetRequest = (email) => {
    var requestOptions = {
      method: 'PUT',
      headers: { 'Authorization': `Bearer ${localStorage.getItem("token")}`, 'Content-Type': 'application/json' }
    };
  
    var requestUrl = `${API_URL}/internal/scanning_users/send_password_reset/${email}`
  
    fetch(requestUrl, requestOptions)
      .then((response) => {
        if (response.ok) {
          response.json().then((resp) => {
            notify(`Password reset request sent to: ${email}`, {
              type: "info",
            });
          });
        } else {
          notify(`Password reset request failed: ${response.status}`, {
            type: "warning",
          });
        }
      })
      .catch((error) => {
        notify(`Request failed. ${error}`, { type: "warning" });
      });
  };
    
  const buttonStyles = {
    width: "300px",
    display: "flex",
    flexDirection: "column",
    paddingTop: "15px",
    paddingBottom: "20px"
  };
  
  const labelStyles = {
    color: "rgba(0, 0, 0, 0.54)",
    marginBottom: "8px"
  };

  const handleResetRequest = (rec) => {
    passwordResetRequest(rec.email)
  };

  const ConfirmationDialog = (props) => {
    const handleCancel = () => {
      setOpen(false)
      notify((`Password reset request canceled`), {type: 'info'})
    };

    const handleOk = () => {
      setOpen(false)
      handleResetRequest(record)
    };

    return (
      <Dialog maxWidth="sm" open={open}>
        <DialogContent>
          <Typography variant="subtitle1">{`Send a password reset email to ${record.email}?`}</Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus aria-label="cancel-reset" onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button aria-label="confirm-reset" onClick={handleOk} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <>
    <Divider style={{marginTop: "8px"}}/>
    <div style={buttonStyles} > 
        <Typography variant="overline" style={labelStyles}>Send a password reset email:</Typography>
        <div>
          <Button color="primary" size="small" variant="outlined" onClick={() => setOpen(true)} aria-label={"reset-pw-button"}>Send</Button>
        </div>
    </div>
    <ConfirmationDialog {...props}/>
    </>
  )
}